import './App.css';

import React, { useState, useEffect, useCallback } from 'react';
import ReactGA from 'react-ga4';

import Admin from './admin2/Admin';
import Logo from './headerFooter/Logo';
import TournamentControl from './tournament/controls/TournamentControl';
import Footer from './headerFooter/Footer';
import Connecting from './dataStatus/Connecting';
import NoData from './dataStatus/NoData';
import LocalizedContext from './i18n/LocalizedContext';
import LanguageSelector from './headerFooter/LanguageSelector';

import urlParameters from './utils/urlParameters';
import callBackend from './utils/callBackend';
import enDict from './i18n/en.json';

import SeriesContainer from './tournament/SeriesContainer';
import Cookies from './headerFooter/Cookies';

const addLanguageCode = (languageCode, pack) => { return ( {...pack, languageCode} );}

function App() {
  const LANGUAGE = 'LANGUAGE';
  const ORGANIZATION = 'ORGANIZATION';

  const DEFAULT_LANGUAGE = 'en';   // As in the BE

  const link = urlParameters().link;
  const admin = urlParameters().admin;

  const langPreselect = localStorage.getItem(LANGUAGE) || DEFAULT_LANGUAGE;
  const [lang, setLang] = useState(addLanguageCode(DEFAULT_LANGUAGE, enDict));   // The dictionary
  const [language, setLanguage] = useState(langPreselect);                       // The selected language
  const [langPack, setLangPack] = useState([{feName: 'English', adjective: DEFAULT_LANGUAGE}]);  // Languages available

  const [organization, setOrganization] = useState(localStorage.getItem( ORGANIZATION ) || 'fie');
  const [cookieConsent, setCookieConsent] = useState(false);
  const [data, setData] = useState({});
  const [selectionMade, setSelectionMade] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const clearContentHandler = () => { 
    setSelectionMade(false);
  };
  const chooseContentHandler = () => { setSelectionMade(true) };
  const acceptCookieHandler = () => { setCookieConsent(true) };

  useEffect(() => {
    document.title = 'Fencing Statistitics';
  }, []);

  const fetchDataHandler = useCallback( () => {
    if( !admin ) {
      ReactGA.initialize("G-NCSL7XM80H");
      ReactGA.send("pageview");

      if (link) {
        setIsLoading(true);
        callBackend('tournament', { onlineLink: link }, result => {
          if( result.isOk ) {
            setData(result.data);
          } else {
            setError(result.error);
          }
          setIsLoading(false);
        });
      } else {
        setIsLoading(true);
        callBackend('mainPage', { organization, language }, result => {
            if( result.isOk ) {
              setData(result.data);
            } else {
              setError(result.error);
            }
            setIsLoading(false);
        });
      }
    }
  }, [link, organization, admin, language]);

  useEffect(() => {
    fetchDataHandler();
  }, [fetchDataHandler, link, admin] );

  const setOrganizationHandler = org => {
    setIsLoading(true);
    callBackend('mainPage', { organization: org, language }, result => {
      if( result.isOk ) {
        setData(result.data);
        setOrganization(org);
        localStorage.setItem(ORGANIZATION, org);
      } else {
        setError(result.error);
      }
      setIsLoading(false);
    });
  };

  // Languages
  const fetchLanguagesHandler = useCallback( () => {
    callBackend('languagePacks', null, result => {
      if( result.isOk ) {
        const pack = result.data;
        setLangPack(pack);

        const preselected = localStorage.getItem(LANGUAGE);
        if(preselected !== DEFAULT_LANGUAGE) {
          const preselectedExists = pack.map( p => p.adjective ).includes(preselected);
          if( preselectedExists ) {
            callBackend('languagePack', {language: preselected, channel: 'stats'}, result => {
              if( result.isOk ) {
                setLang(addLanguageCode(preselected,result.data));
              }
            });
          } else {
            localStorage.setItem(LANGUAGE, DEFAULT_LANGUAGE);
          }
        }
      }
    });
  }, [setLangPack, setLang]);

  useEffect(() => {
    fetchLanguagesHandler();
  }, [fetchLanguagesHandler] );

  const languageHandler = l => {
    setLanguage(l);
    localStorage.setItem(LANGUAGE, l);

    if( l === DEFAULT_LANGUAGE) {
      setLang(addLanguageCode(l, enDict));
    } else {
      callBackend('languagePack', {language: l, channel: 'stats'}, result => {
          if( result.isOk ) {
            setLang(addLanguageCode(l,result.data));
          }
      });
    }
  };


  let content = '';
  if( admin ) content = <Admin />
  else if( error ) content = <NoData/>
  else if( isLoading ) content = <Connecting/>
  else if( link ) content = <TournamentControl tournament={data} selectionMade={selectionMade} chooseContentHandler={chooseContentHandler} />
  else content = 
    <div>
      <SeriesContainer data={data} selectionMade={selectionMade}
        organization={organization} setOrganizationHandler={setOrganizationHandler}
        chooseContentHandler={chooseContentHandler} />
    </div>

  const consent = cookieConsent || admin ? '' : <Cookies acceptCookieHandler={acceptCookieHandler}/>;

  return (
    <div className="App">
      <LocalizedContext.Provider value={lang}>
        {consent}
        <Logo clearContentHandler={clearContentHandler} />
        <LanguageSelector langPack={langPack} language={language} setLangHandler={languageHandler}/>
        {content}
        <hr className="App-hr"/>
        <Footer/>
      </LocalizedContext.Provider>
    </div>
  );
}

export default App;
