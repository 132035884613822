import React, { useContext } from 'react';
import localize from '../../i18n/localize';
import LocalizedContext from '../../i18n/LocalizedContext';

import './TeamSummary.css';
import TeamMatch from './TeamMatch';

const APPEARANCE_TRASHHOLD = 0.2;

const TeamSummary = props => {
    const teamData = props.teamData;
    const teamSelectedHandler = props.teamSelectedHandler;
    const lang = useContext(LocalizedContext);

    const appearance = (given, received, diff) => {
        if( diff === 0 ) return 'TeamSummary-tie';
        else if( diff > 0 ) {
            if( diff / given > APPEARANCE_TRASHHOLD ) return 'TeamSummary-awesome';
            else return 'TeamSummary-good';
        } else {
            if( -diff / received > APPEARANCE_TRASHHOLD ) return 'TeamSummary-horror';
            else return 'TeamSummary-bad';
        }
    }

    const diffValue = d => {
        if( d > 0 ) return '+' + d;
        else return d;
    }

    const ourFencers = teamData.ourFencers.map( f =>
        <li key={f.name} className={appearance(f.scoreMe, f.scoreOpponent, f.diff)}>{f.name}&nbsp;&nbsp;{f.scoreMe} : {f.scoreOpponent} ({diffValue(f.diff)})</li>
    );

    const teamMatches = teamData.teamBouts.map( t =>
        <TeamMatch key={t.opposingTeam.name} opposingTeam={t.opposingTeam} ourFencers={t.ourFencers} appearance={appearance} teamSelectedHandler={teamSelectedHandler} />
    );

    return (
        <div>
            <div className='TeamSummary-label'>{localize(lang, 'tournamentResults')}</div>
            <ol className='TeamSummary-list'>
                {ourFencers}
            </ol>

            <div className='TeamSummary-label'>{localize(lang, 'matchResults')}</div>
                {teamMatches}
        </div>
    );
}

export default TeamSummary;