import React, { useContext, useState } from 'react';
import localize from '../../i18n/localize';
import LocalizedContext from '../../i18n/LocalizedContext';

import Opponent from './Opponent';
import SearchFencer from '../search/SearchFencer';
import './OpponentDisplay.css';

const OpponentDisplay = props => {
    const fencer = props.fencer;
    const event = props.event;
    const lang = useContext(LocalizedContext);

    const [opponent, setOpponent] = useState('');

    const setOpponentHandler = opponentToSet => {
        setOpponent(opponentToSet);
    };

    const opponentElement = opponent ? <Opponent fencer={fencer} opponent={opponent} event={event}/> : '';

    return (
        <div>
            <div className='OpponentDisplay-header'>
                {localize(lang, 'allBouts')}
            </div>
            <div className='OpponentDisplay-text'>
                {localize(lang, 'selectOpponent')}
            </div>
            <SearchFencer setFencerHandler={setOpponentHandler}>
                {opponentElement}
            </SearchFencer>
        </div>
    );
}

export default OpponentDisplay;