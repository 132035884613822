import React from 'react';
import './TournamentDisplay.css';

const TournamentDisplay = props => {
    return (
        <div>
            <div className='TournamentDisplay-label'>{props.name}</div>
            {props.children}
        </div>
    );
}

export default TournamentDisplay;