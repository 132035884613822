import React, { useCallback, useEffect, useContext } from 'react';
import localize from '../../i18n/localize';
import LocalizedContext from '../../i18n/LocalizedContext';

import './OrganizationSelector.css'

const NUM_COLUMNS = 3;

const OrganizationSelector = props => {
    const availableOrganizations = props.availableOrganizations;
    const organizationSelectedHandler = props.organizationSelectedHandler;
    const cancelHandler = props.cancelHandler;
    const organizationMap = props.organizationMap;
    const lang = useContext(LocalizedContext);

    const numOrganizations = availableOrganizations.length;
    const numRows = numOrganizations % NUM_COLUMNS === 0 ? numOrganizations / NUM_COLUMNS : numOrganizations / NUM_COLUMNS + 1;

    const escFunction = useCallback((event) => {
        if (event.key === "Escape") {
            cancelHandler();
        }
    }, [cancelHandler]);
    
    useEffect(() => {
        document.addEventListener("keydown", escFunction, false);

        return () => {
            document.removeEventListener("keydown", escFunction, false);
        };
    }, [escFunction]);



    const makeEmptyCell = index => {
        return ( <td key={index}></td> );
    };
    
    const makeCell = (index, org) => {
        const orgData = organizationMap[org];

        return (
            <td key={index} className='OrganizationSelector' onClick={ () => organizationSelectedHandler(org) } >
                <img
                    src={orgData.img}
                    alt={org}
                    width='70px'
                />
                <div className='OrganizationSelector-cell'>{localize(lang, orgData.key)}</div>
            </td> );
    };
    
    const makeRow = (index, cells) => {
        return ( <tr key={index}>{cells}</tr> );
    };
    
    const makeTable = (rows) => {
        return ( <div className='OrganizationSelector' onClick={cancelHandler}>
                    <div className='OrganizationSelector-selector'>
                        <div className='OrganizationSelector-header'>{localize(lang, 'pickOrganization')}:</div>
                        <table className='OrganizationSelector'>
                            <tbody>
                                {rows}
                            </tbody>
                        </table>
                    </div>
                </div>
                );
    };

    let i = 0;

    let rows = [];
    for( let r = 0; r < numRows; r++ ) {
        const cells = [];
        for( let c = 0; c < NUM_COLUMNS; c++ ) {
            const cell = i >= numOrganizations ? makeEmptyCell(i) : makeCell(i, availableOrganizations[i]);
            cells.push(cell);
            i++;
        }
        rows.push(makeRow(i, cells));
    }

    return makeTable(rows);
}

export default OrganizationSelector;