import React, { useState, useContext, useCallback, useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import localize from '../i18n/localize';
import LocalizedContext from '../i18n/LocalizedContext';
import { addCheckoutId, addOffering, checkoutStepPackage } from './checkoutSlice';

import './CheckoutStep.css';
import BottomBar from './BottomBar';
import callBackend from '../utils/callBackend';

// B2B Package
const CheckoutStepPackage = props => {
    const cancelCheckout = props.cancelCheckout;
    const lang = useContext(LocalizedContext);
    const dispatch = useDispatch();

    const [product, setProduct] = useState(useSelector(state => state.checkout.product));
    const id = useSelector(state => state.checkout.id);
    const offering = useSelector(state => state.checkout.offering);

    const fetchDataHandler = useCallback( () => {
        callBackend('products', { language: lang.languageCode, offering: 'b2bOffering' },
            result => { if( result.isOk ) dispatch(addOffering(result.data.products)); }
        );
      }, [dispatch, lang]);
    
    useEffect(() => {
        fetchDataHandler();
    }, [fetchDataHandler, lang] );

    const changeProductHandler = event => {
        setProduct(event.target.value);
    };

    const submitStep = () => {
        const DATA = { product };
        dispatch(checkoutStepPackage(DATA));
        callBackend('checkout',
            { id, product: DATA }, 
            result => { if( result.isOk ) dispatch(addCheckoutId(result.data.id)); }
        );
    };

    const canSubmit = product;

    const productNames = offering.map( offer => {
        return (
        <td className='CheckoutStep' width={'30%'} key={offer.id}>
            <label>
                <input className='CheckoutStep-radio' type='radio' 
                    value={offer.id}
                    checked={product === offer.id}
                    onChange={changeProductHandler} />
            </label>
            <b>
                {offer.name}
            </b>
        </td>);
    });

    const productDescs = offering.map( offer => {
        return (
        <td className='CheckoutStep' key={offer.id}>
            <ul className='CheckoutStep'>
                <li className='CheckoutStep'>{offer.description}</li>
                <li className='CheckoutStep'>{localize(lang, 'price')}: {offer.price} {offer.currency}</li>
            </ul>
        </td>);
    });

    return (
        <div className='CheckoutStep-content'>
            <div className='CheckoutStep'>
                {localize(lang, 'product')}:
                <table>
                    <tbody>
                        <tr>{productNames}</tr>
                        <tr>{productDescs}</tr>
                    </tbody>
                </table>
            </div>
            <BottomBar cancelCheckout={cancelCheckout} canSubmit={canSubmit} submitStep={submitStep} />
        </div>);
}

export default CheckoutStepPackage