import React, { useContext } from 'react';
import localize from '../i18n/localize';
import LocalizedContext from '../i18n/LocalizedContext';

import './LanguageSelector.css';

const LanguageSelector = props => {
    const langPack = props.langPack;
    const language = props.language;
    const langHandler = props.setLangHandler;

    const lang = useContext(LocalizedContext);


    const langs = langPack.map( data => {
        const clazz = language === data.adjective ? 'LanguageSelector-selected' : 'LanguageSelector';
        const handler = language === data.adjective ? () => {} : () => { langHandler(data.adjective) };

        return(<span
                key={data.adjective}
                className={clazz}
                onClick={handler}>{data.feName}</span>)
    });

    return (
        <div className='LanguageSelector'>
            {localize(lang, 'language')}:&nbsp;&nbsp;
            {langs}
        </div>
    );
}

export default LanguageSelector;