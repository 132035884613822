import React, { useContext } from 'react';
import localize from '../i18n/localize';
import LocalizedContext from '../i18n/LocalizedContext';

import './Logo.css';

const Logo = props => {
    const clearContentHandler = props.clearContentHandler;
    const lang = useContext(LocalizedContext);

    return (
        <div className='Logo' onClick={clearContentHandler} >
            <div className='Logo-img'><img width='100%' alt='Logo' src='/logo-380.png' /></div>
            <h1 className='Logo'>{localize(lang, 'fencingStats')}</h1>
        </div>
    );
};

export default Logo;