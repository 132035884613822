import React, { useContext } from 'react';

import localize from '../../i18n/localize';
import { tournamentName, eventName, eventKey } from '../../utils/tournamentName';

import LocalizedContext from '../../i18n/LocalizedContext';

import './InsightsGraph.css';
import './FourBox.css';

import FourBoxCounts from './FourBoxCounts';
import constants from './constants';
import FourBoxYesNo from './FourBoxYesNo';
import Gartner from './Gartner';

const makeOffset = (ups, downs) => {
    const yAxisUp = Math.max(...ups) * constants.BOX_HEIGHT + constants.VERTICAL_MARGIN;
    const yAxisDown = Math.max(...downs) * constants.BOX_HEIGHT;
    const yAxis = yAxisUp + yAxisDown;
    const xAxis = constants.HORIZONTAL_MARGIN;
    return { yAxisUp, yAxisDown, yAxis, xAxis };
}

const makeTightBouts = (bouts, lang) => {
    const offset = makeOffset(bouts.map(e => e.v15 + e.v5), bouts.map(e => e.d14 + e.d4));
    var offsetCount = offset.xAxis;
    const tightBouts = bouts.map( (a, i) => {
        const key = eventKey(a);
        const counts = [a.v15, a.v5, a.d4, a.d14];
        const names = ['V15', 'V5', 'D4', 'D14'];
        const adjustedOffset = { x: offsetCount, y: offset.yAxisUp };
        offsetCount += constants.BOX_WIDTH + constants.BOX_SPACE;
        return <FourBoxCounts key={key} counts={counts} names={names} offset={adjustedOffset} i={i+1} />
    });

    const svgDimensions = '0 0 ' + constants.WIDTH + ' ' + offset.yAxis;
    const yOffsetW =  offset.yAxisUp - constants.BOX_HEIGHT + constants.BOX_TEXT_Y;
    const yOffsetD =  offset.yAxisUp + constants.BOX_TEXT_Y;
    const wins = offset.yAxisUp === constants.VERTICAL_MARGIN ? '' : <text x={constants.LINE_HORIZONTAL_MARGIN} y={yOffsetW} className='darkText'>{localize(lang, 'wins')}</text> 
    const defeats = offset.yAxisDown === 0 ? '' : <text x={constants.LINE_HORIZONTAL_MARGIN} y={yOffsetD} className='darkText'>{localize(lang, 'defeats')}</text> 

    return (
        <svg viewBox={svgDimensions}>
            {tightBouts}
            {wins}
            <line x1={constants.LINE_HORIZONTAL_MARGIN} y1={offset.yAxisUp}
                  x2={constants.WIDTH - constants.LINE_HORIZONTAL_MARGIN} y2={offset.yAxisUp}
                  stroke={constants.BASE_LINE_COLOR} strokeWidth={constants.BASE_LINE_WIDTH} />
            {defeats}
        </svg>
    );
}

const makeBoutExpectations = (bouts, lang) => {
    const offset = makeOffset(bouts.map(e => e.vExpectingV + e.vExpectingD), bouts.map(e => e.dExpectingV + e.dExpectingD));
    var offsetCount = offset.xAxis;
    const boutExpectations = bouts.map( (a, i) => {
        const key = eventKey(a);
        const counts = [a.vExpectingD, a.vExpectingV, a.dExpectingD, a.dExpectingV];
        const names = [localize(lang, 'surprise'), localize(lang, 'expectation'), localize(lang, 'expectation'), localize(lang, 'surprise')];
        const adjustedOffset = { x: offsetCount, y: offset.yAxisUp };
        offsetCount += constants.BOX_WIDTH + constants.BOX_SPACE;
        return <FourBoxCounts key={key} counts={counts} names={names} offset={adjustedOffset} i={i+1} />
    });

    const svgDimensions = '0 0 ' + constants.WIDTH + ' ' + offset.yAxis;
    const yOffsetW =  offset.yAxisUp - constants.BOX_HEIGHT + constants.BOX_TEXT_Y;
    const yOffsetD =  offset.yAxisUp + constants.BOX_TEXT_Y;
    const wins = offset.yAxisUp === constants.VERTICAL_MARGIN ? '' : <text x={constants.LINE_HORIZONTAL_MARGIN} y={yOffsetW} className='darkText'>{localize(lang, 'wins')}</text> 
    const defeats = offset.yAxisDown === 0 ? '' : <text x={constants.LINE_HORIZONTAL_MARGIN} y={yOffsetD} className='darkText'>{localize(lang, 'defeats')}</text> 

    return (
        <svg viewBox={svgDimensions}>
            {boutExpectations}
            {wins}
            <line x1={constants.LINE_HORIZONTAL_MARGIN} y1={offset.yAxisUp}
                  x2={constants.WIDTH - constants.LINE_HORIZONTAL_MARGIN} y2={offset.yAxisUp}
                  stroke={constants.BASE_LINE_COLOR} strokeWidth={constants.BASE_LINE_WIDTH} />
            {defeats}
        </svg>
    );
}

const makePoolResults = (bouts, lang) => {
    const offset = makeOffset([2], [2]);
    var offsetCount = offset.xAxis;
    const poolResults = bouts.map( (a, i) => {
        const key = eventKey(a);
        const yesNo = [a.vFirst, a.vSecond, a.vBeforeLast, a.vLast];
        const names = yesNo.map( e => e ? localize(lang, 'win') : localize(lang, 'loss') );
        const adjustedOffset = { x: offsetCount, y: offset.yAxisUp };
        offsetCount += constants.BOX_WIDTH + constants.BOX_SPACE;
        return <FourBoxYesNo key={key} yesNo={yesNo} names={names} offset={adjustedOffset} i={i+1} />
    });

    const svgDimensions = '0 0 ' + constants.WIDTH + ' ' + offset.yAxis;
    const yOffset1 =  offset.yAxisUp - 2 * constants.BOX_HEIGHT + constants.BOX_TEXT_Y;
    const yOffset2 =  offset.yAxisUp - constants.BOX_HEIGHT + constants.BOX_TEXT_Y;
    const yOffset3 =  offset.yAxisUp + constants.BOX_TEXT_Y;
    const yOffset4 =  offset.yAxisUp + constants.BOX_HEIGHT + constants.BOX_TEXT_Y;

    return (
        <svg viewBox={svgDimensions}>
            {poolResults}
            <text x={constants.LINE_HORIZONTAL_MARGIN} y={yOffset1} className='darkText'>{localize(lang, 'first')}</text>
            <text x={constants.LINE_HORIZONTAL_MARGIN} y={yOffset2} className='darkText'>{localize(lang, 'second')}</text>
            <line x1={constants.LINE_HORIZONTAL_MARGIN} y1={offset.yAxisUp}
                  x2={constants.WIDTH - constants.LINE_HORIZONTAL_MARGIN} y2={offset.yAxisUp}
                  stroke={constants.BASE_LINE_COLOR} strokeWidth={constants.BASE_LINE_WIDTH} />
            <text x={constants.LINE_HORIZONTAL_MARGIN} y={yOffset3} className='darkText'>{localize(lang, 'bLast')}</text>
            <text x={constants.LINE_HORIZONTAL_MARGIN} y={yOffset4} className='darkText'>{localize(lang, 'last')}</text>
        </svg>
    );
}

// Extrapolate data for bout expecations
const extrapolateBoutExpectations = d => {
    const x = d.xValue;
    const y = d.yValue;
    if( x === 0 && y === 0 ) return d;

    const a = 2 * (Math.max(x, y) - Math.abs(x - y));
    const x1 = x + (a * x) / (x + y);
    const y1 = y + (a * y) / (x + y);

    return { ...d, xValue: x1, yValue: y1 };
}

const InsightsGraph = props => {
    const fencer = props.fencer;
    const insights = props.insights;
    const lang = useContext(LocalizedContext);

    var tightBoutsResults = '';
    if( insights.tightBout.length > 0 ) {
        const tightBouts = makeTightBouts(insights.tightBout, lang);
        const tightBoutIndex = insights.tightBout.map( (a, i) => <li key={i}>{tournamentName(a)} {eventName(a)}</li> );
        const lablesTightBouts = {
            leftBottom: localize(lang, 'rarelyTight'),
            rightBottom: localize(lang, 'oftenTight'),
            topLeft: localize(lang, 'winning'),
            bottomLeft: localize(lang, 'loosing'),
    
            q1: localize(lang, 'getJob'),
            q2: localize(lang, 'tightIs'),
            q3: localize(lang, 'avoidingTight'),
            q4: localize(lang, 'crackingUnder')
        }
        const gartnerTB = <Gartner fencer={fencer} data={insights.gartnerTightResult} labels={lablesTightBouts} />;
        tightBoutsResults = 
            <div>
                <div className='InsightsGraph-label'>{localize(lang, 'tightBouts')}</div>
                <div className='InsightsGraph-description'>{localize(lang, 'allWins')}:</div>
                {tightBouts}
                <ol className='InsightsGraph-events'>{tightBoutIndex}</ol>
                <div className='InsightsGraph-description'>
                    {localize(lang, 'comparingBouts', {'@userid@': fencer})}:
                </div>
                <div className='InsightsGraph-description'>{gartnerTB}</div>
            </div>;
    }

    var boutExpectationsResults = '';
    if( insights.expectedResultSeed.length > 0 ) {
        const boutExpectations = makeBoutExpectations(insights.expectedResultSeed, lang);
        const boutExpectationsIndex = insights.expectedResultSeed.map( (a, i) => <li key={i}>{tournamentName(a)} {eventName(a)}</li> );
        const lablesBoutExpectations = {
            leftBottom: localize(lang, 'expectedWins'),
            rightBottom: localize(lang, 'surpWins'),
            topLeft: localize(lang, 'surpLosses'),
            bottomLeft: localize(lang, 'expectedLosses'),
    
            q1: localize(lang, 'wellSeed'),
            q2: localize(lang, 'fullOf'),
            q3: localize(lang, 'stableRock'),
            q4: localize(lang, 'raisingStar')
        }
        const erData = insights.gartnerExpectedResult.map(extrapolateBoutExpectations);
        const gartnerER = <Gartner fencer={fencer} data={erData} labels={lablesBoutExpectations} />;
        boutExpectationsResults = 
            <div>
                <div className='InsightsGraph-label'>{localize(lang, 'boutExpect')}</div>
                <div className='InsightsGraph-description'>{localize(lang, 'resultSplit')}:</div>
                {boutExpectations}
                <ol className='InsightsGraph-events'>{boutExpectationsIndex}</ol>
                <div className='InsightsGraph-description'>
                    {localize(lang, 'comparingExpected', {'@userid@': fencer})}:
                </div>
                <div className='InsightsGraph-description'>{gartnerER}</div>
            </div>;
    }

    var poolResultsResults = '';
    if( insights.poolResults.length > 0 ) {
        const poolResults = makePoolResults(insights.poolResults, lang);
        const poolResultsIndex = insights.poolResults.map( (a, i) => <li key={i}>{tournamentName(a)} {eventName(a)}</li> );
        poolResultsResults =
            <div>
                <div className='InsightsGraph-label'>{localize(lang, 'poolResult')}</div>
                <div className='InsightsGraph-description'>{localize(lang, 'winsOrDef')}:</div>
                {poolResults}
                <ol className='InsightsGraph-events'>{poolResultsIndex}</ol>
            </div>;
    }

    var highlights = '';
    if( insights.highlights ) {
        const h1 = insights.highlights.highlight1;
        const h2 = insights.highlights.highlight2;
        const h3 = insights.highlights.highlight3;

        highlights =
            <div>
                <div className='InsightsGraph-label'>{localize(lang, 'highlights')}</div>
                <div className='InsightsGraph-description'>{localize(lang, 'bestThree')}:</div>
                <ul className='InsightsGraph-events'>
                    <li><b>{h1.fencerName}</b> {h1.touchesGiven}:{h1.touchesReceived} at {tournamentName(h1)} ({eventName(h1)})</li>
                    <li><b>{h2.fencerName}</b> {h2.touchesGiven}:{h2.touchesReceived} at {tournamentName(h2)} ({eventName(h2)})</li>
                    <li><b>{h3.fencerName}</b> {h3.touchesGiven}:{h3.touchesReceived} at {tournamentName(h3)} ({eventName(h3)})</li>
                </ul>
            </div>;
    }

    var lastLossesResults = '';
    if( insights.lastLosses.length > 0 ) {
        const lastLosses = insights.lastLosses.map( (a, i) => <li key={i}><b>{a.fencerName}</b> {a.touchesGiven}:{a.touchesReceived} at {tournamentName(a)} ({eventName(a)})</li> );
        lastLossesResults =
            <div>
                <div className='InsightsGraph-label'>{localize(lang, 'lastLosses')}</div>
                <div className='InsightsGraph-description'>{localize(lang, 'lastFive')}:</div>
                <ul className='InsightsGraph-events'>{lastLosses}</ul>
            </div>;
    }

    return (
        <div>
            {tightBoutsResults}
            {boutExpectationsResults}
            {poolResultsResults}
            {highlights}
            {lastLossesResults}
        </div>
    );
}

export default InsightsGraph;