import React from 'react';
import './Ok.css';

const Ok = props => {
    const clickHandler = props.clickHandler;

    return (
        <button className='Ok' onClick={clickHandler}>OK</button>
    );
}

export default Ok;