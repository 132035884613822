import React, { useState, useCallback, useEffect, useContext } from 'react';
import localize from '../../i18n/localize';
import LocalizedContext from '../../i18n/LocalizedContext';

import './SearchFencer.css';
import callBackend from '../../utils/callBackend';

const DELAY = 300;
let lastStrike = 0;

const SearchFencer = props => {
    const setFencerHandler = props.setFencerHandler;
    const previousFencer = props.previousFencer;
    const children = props.children;
    const lang = useContext(LocalizedContext);

    const [inputValue, setInputValue] = useState('');
    const [showSearch, setShowSearch] = useState(false);
    const [tooManyResults, setTooManyResults] = useState(false);
    const [results, setResults] = useState([]);
    const [selectedFencer, setSelectedFencer] = useState('');

    // Cancel search
    const cancelHandler = useCallback (() => {
        setInputValue('');
        setShowSearch(false);
        setSelectedFencer('');
        setFencerHandler('');
    }, [setFencerHandler]);

    const escFunction = useCallback((event) => {
        if (event.key === "Escape") {
            cancelHandler();
        }
    }, [cancelHandler]);
    
    useEffect(() => {
        document.addEventListener("keydown", escFunction, false);

        return () => {
            document.removeEventListener("keydown", escFunction, false);
        };
    }, [escFunction]);

    // Run Search
    const changeInputHandler = event => {
        const data = event.target.value;
        lastStrike = Date.now();
        searchFencer(data);
    };

    const searchFencer = data => {
        setInputValue(data);
        setSelectedFencer('');
        setFencerHandler('');

        if( data ) {
            setTimeout( () => {
                const now = Date.now();
                if( now - lastStrike >= DELAY ) {
                    callBackend('search', { term: data, maxResults: 16 }, result => {
                        if( result.isOk ) {
                            setShowSearch(true);
                            setTooManyResults(result.data.tooManyResults);
                            setResults(result.data.results);
                        }
                    });
                }
            }, DELAY);
        } else {
            setShowSearch(false);
        }
    };

    // Act on selected fencer
    const clickHandler = fencer => {
        setSelectedFencer(fencer);
        setFencerHandler(fencer);
    };

    const fillFencerHandler = () => {
        setInputValue(previousFencer);
        setSelectedFencer('');
        setFencerHandler('');

        callBackend('search', { term: previousFencer, maxResults: 16 }, result => {
            if( result.isOk && result.data.results.length === 1 ) {
                clickHandler( result.data.results[0] );
                setShowSearch(true);
            }
        });
    }

    const goBackHandler = () => {
        setSelectedFencer('');
        setFencerHandler('');
    };

    const eventList = () => {
        return (
            <div>
                <div className='SearchFencer-name'>{selectedFencer}</div>
                {children}
                <div className='SearchFencer-back'>
                    <span className='SearchFencer-text' onClick={goBackHandler}>{localize(lang, 'backToList')}</span>
                </div>
            </div>);
    };

    // Show Search Results
    const makeCell = fencer => {
        if( fencer ) return (
            <td className='SearchFencer'>
                <span className='SearchFencer-text' onClick={ () => clickHandler(fencer) }>{fencer}</span>
            </td>
        );
        else return (
            <td className='SearchFencer'></td>
        );
    };

    const makeRow = ( c1, c2, i ) => {
        return (
            <tr key={i}>{c1}{c2}</tr>
        );
    };

    const displaySearchResults = (tooManyResults, results) => {
        if( tooManyResults ) {
            return (
                <div className='SearchFencer-message'>
                    {localize(lang, 'tooManyResults')}
                </div>
            );
        } else if( results.length === 0 ) {
            return (
                <div className='SearchFencer-message'>
                    {localize(lang, 'noResults')}
                </div>
            );
        } else {
            const table = [];
            const r = [...results];
            let i = 0;

            while( r.length > 0 ) {
                const el1 = r.shift();
                const el2 = r.length > 0 ? r.shift() : '';
                const row = makeRow( makeCell(el1), makeCell(el2), i );
                table.push( row );
                i++;
            }

            return(
                <table className='SearchFencer'>
                    <tbody>
                        {table}
                    </tbody>
                </table>
            );
        }
    };

    const searchResult = (showSearch && !selectedFencer) ? 
        <div className='SearchFencer-dropdown'>
            {displaySearchResults( tooManyResults, results )}
        </div> : '';

    const searchResult2 = (showSearch && selectedFencer) ? eventList() : '';
    const previousSearch = previousFencer ? 
        <span className='SearchFencer-info'>
            ({localize(lang, 'previously')}
            <span className='SearchFencer-text' onClick={fillFencerHandler}>{previousFencer}</span>)
        </span> : '';

    return (
        <div className='SearchFencer-envelope'>
            <div className='SearchFencer-header'>
                {localize(lang, 'searchFor')}
                {previousSearch}
            </div>
            <div className='SearchFencer-clean'>
                <input className='SearchFencer' type='text' onChange={changeInputHandler} value={inputValue} />
                <span className='SearchFencer-text' onClick={cancelHandler}>{localize(lang, 'clear')}</span>
            </div>
            {searchResult}
            {searchResult2}
        </div>
    );
}

export default SearchFencer;