import React, { useState } from 'react';
import Direction from './Direction';

import Ok from './Ok';
import './TournamentMgmt.css';

const TournamentMgmt = props => {
    const submitHandler = props.submitHandler;
    const updateTourneamentHandler = props.updateTourneamentHandler;
    const tournamentCreated = props.tournamentCreated;

    const [rainbowValue, setRainbowValue] = useState('');
    const [urlSnippetValue, setUrlSnippetValue] = useState('');
    const [dataSource, setDataSource] = useState('');
    const [tournamentName, setTournamentName] = useState('');
    const [eventName, setEventName] = useState('');

    const clickHandler = () => {
        const eventDirectly = { tournamentName, eventName };
        (tournamentName && eventName) ?
            submitHandler({rainbow: rainbowValue, urlSnippet: urlSnippetValue, dataSource, eventDirectly}) :
            submitHandler({rainbow: rainbowValue, urlSnippet: urlSnippetValue, dataSource});

        setRainbowValue('');
        setUrlSnippetValue('');
        setDataSource('');
        setTournamentName('');
        setEventName('');
    }

    const changeRainbowValueHandler = event => {
        setRainbowValue(event.target.value);
    };

    const changeUrlSnippetValueHandler = event => {
        setUrlSnippetValue(event.target.value);
    };

    const changeDataSourceHandler = event => {
        setDataSource(event.target.value);
        setTournamentName('');
        setEventName('');
    }

    const changeTournamentNameValueHandler = event => {
        setTournamentName(event.target.value);
    }

    const changeEventNameValueHandler = event => {
        setEventName(event.target.value);
    }

    const fetchHandler = () => {
        updateTourneamentHandler({returning: 'last'});
    }

    if( tournamentCreated ) return (
        <div className='TournamentMgmt'>
            Fetch Events of the new tournament (allow time to download the content):
            <Direction clickHandler={fetchHandler} name='Fetch Events' />
        </div>
    );

    return (
        <div className='TournamentMgmt'>
            Source: <select value={dataSource} onChange={changeDataSourceHandler} className='TournamentMgmt'>
                <option value="">Select</option>
                <option value="fencingTime">Fencing Time</option>
                <option value="enGarde">En Garde</option>
                <option value="fencingWW">Fencing World Wide</option>
            </select>

            { dataSource ?
                <span>
                    Access key: <input className='TournamentMgmt TournamentMgmt-short'
                        type='text'
                        onChange={changeRainbowValueHandler}
                        value={rainbowValue} />
                    URL snippet: <input className='TournamentMgmt TournamentMgmt-long'
                        type='text'
                        onChange={changeUrlSnippetValueHandler}
                        value={urlSnippetValue} />
                </span> : '' }

            { dataSource === 'fencingWW' ?
                <div className='TournamentMgmt-additional'>
                    Tournament name: <input className='TournamentMgmt TournamentMgmt-long'
                        type='text'
                        onChange={changeTournamentNameValueHandler}
                        value={tournamentName} />
                    Event name: <input className='TournamentMgmt TournamentMgmt-long'
                        type='text'
                        onChange={changeEventNameValueHandler}
                        value={eventName} />
                </div> : '' }

            { tournamentName && eventName ? <div className='TournamentMgmt-strong'>CREATING ONE EVENT ONLY!</div> : '' }
            { dataSource && rainbowValue && urlSnippetValue ? <Ok clickHandler={clickHandler} /> : '' }
        </div>
    );
}

export default TournamentMgmt;