import React, { useState, useContext } from "react";
import { useSelector, useDispatch } from 'react-redux';
import localize from '../i18n/localize';
import LocalizedContext from '../i18n/LocalizedContext';
import { addCheckoutId, checkoutStepTournament } from './checkoutSlice';

import './CheckoutStep.css';
import BottomBar from './BottomBar';
import callBackend from '../utils/callBackend';

const CheckoutStepTournament = props => {
    const cancelCheckout = props.cancelCheckout;
    const lang = useContext(LocalizedContext);
    const dispatch = useDispatch();

    const [age, setAge] = useState(useSelector(state => state.checkout.age));
    const [competition, setCompetition] = useState(useSelector(state => state.checkout.competition));
    const [gender, setGender] = useState(useSelector(state => state.checkout.gender));
    const [weapon, setWeapon] = useState(useSelector(state => state.checkout.weapon));
    const id = useSelector(state => state.checkout.id);

    const changeAgeHandler = event => {
        setAge(event.target.value);
    };

    const changeCompetitionHandler = event => {
        setCompetition(event.target.value);
    };

    const changeGenderHandler = event => {
        setGender(event.target.value);
    };

    const changeWeaponHandler = event => {
        setWeapon(event.target.value);
    };

    const submitStep = () => {
        const DATA = { age, competition, gender, weapon };
        dispatch(checkoutStepTournament(DATA));
        callBackend('checkout',
            { id, tournament: DATA }, 
            result => { if( result.isOk ) dispatch(addCheckoutId(result.data.id)); }
        );
    };

    const canSubmit = age && competition && gender && weapon;

    return (
        <div className='CheckoutStep-content'>
            <div className='CheckoutStep'>
                <table className='CheckoutStep'>
                    <tbody>
                        <tr>
                            <td className='CheckoutStep'>{localize(lang, 'age')}:</td>
                            <td className='CheckoutStep'>
                                <label>
                                    <input className='CheckoutStep-radio' type='radio' 
                                        value='cadet'
                                        checked={age === 'cadet'}
                                        onChange={changeAgeHandler} />
                                    {localize(lang, 'cadet')}
                                </label>
                            </td>
                            <td className='CheckoutStep'>
                                <label>
                                    <input className='CheckoutStep-radio' type='radio' 
                                        value='junior'
                                        checked={age === 'junior'}
                                        onChange={changeAgeHandler} />
                                    {localize(lang, 'junior')}
                                </label>
                            </td>
                            <td className='CheckoutStep'>
                                <label>
                                    <input className='CheckoutStep-radio' type='radio' 
                                        value='senior'
                                        checked={age === 'senior'}
                                        onChange={changeAgeHandler} />
                                    {localize(lang, 'senior')}
                                </label>
                            </td>
                        </tr>

                        <tr>
                            <td className='CheckoutStep'>{localize(lang, 'competition')}:</td>
                            <td className='CheckoutStep'>
                                <label>
                                    <input className='CheckoutStep-radio' type='radio' 
                                        value='individual'
                                        checked={competition === 'individual'}
                                        onChange={changeCompetitionHandler} />
                                    {localize(lang, 'individual')}
                                </label>
                            </td>
                            <td className='CheckoutStep'>
                                <label>
                                    <input className='CheckoutStep-radio' type='radio' 
                                        value='team'
                                        checked={competition === 'team'}
                                        onChange={changeCompetitionHandler} />
                                    {localize(lang, 'team')}
                                </label>
                            </td>
                            <td className='CheckoutStep' />
                        </tr>

                        <tr>
                            <td className='CheckoutStep'>{localize(lang, 'gender')}:</td>
                            <td className='CheckoutStep'>
                                <label>
                                    <input className='CheckoutStep-radio' type='radio' 
                                        value='men'
                                        checked={gender === 'men'}
                                        onChange={changeGenderHandler} />
                                    {localize(lang, 'men')}
                                </label>
                            </td>
                            <td className='CheckoutStep'>
                                <label>
                                    <input className='CheckoutStep-radio' type='radio' 
                                        value='women'
                                        checked={gender === 'women'}
                                        onChange={changeGenderHandler} />
                                    {localize(lang, 'women')}
                                </label>
                            </td>
                            <td className='CheckoutStep' />
                        </tr>

                        <tr>
                            <td className='CheckoutStep'>{localize(lang, 'weapon')}:</td>
                            <td className='CheckoutStep'>
                                <label>
                                    <input className='CheckoutStep-radio' type='radio' 
                                        value='foil'
                                        checked={weapon === 'foil'}
                                        onChange={changeWeaponHandler} />
                                    {localize(lang, 'foil')}
                                </label>
                            </td>
                            <td className='CheckoutStep'>
                                <label>
                                    <input className='CheckoutStep-radio' type='radio' 
                                        value='epee'
                                        checked={weapon === 'epee'}
                                        onChange={changeWeaponHandler} />
                                    {localize(lang, 'epee')}
                                </label>
                            </td>
                            <td className='CheckoutStep'>
                                <label>
                                    <input className='CheckoutStep-radio' type='radio' 
                                        value='sabre'
                                        checked={weapon === 'sabre'}
                                        onChange={changeWeaponHandler} />
                                    {localize(lang, 'sabre')}
                                </label>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <BottomBar cancelCheckout={cancelCheckout} canSubmit={canSubmit} submitStep={submitStep} />
        </div>);
}

export default CheckoutStepTournament