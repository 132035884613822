import React from 'react';

import '../essentials/EssentialButton.css';
import callBackend from '../utils/callBackend';

const NameMapped = props => {
    const data = props.data;
    const fetchMapping = props.fetchMapping;
    const alternative = props.alternative;

    const confirmed = () => {
        callBackend('approveNameMapping', {nameFound: data.nameFound, nameReal: data.nameReal}, result => {
            if( result.isOk ) {
                fetchMapping();
            }
        });
    }

    const update = () => {
        callBackend('updateNameMapping', { nameFound: data.nameFound, nameReal: alternative, nameWrong: data.nameReal }, result => {
            if( result.isOk ) {
                fetchMapping();
            }
        });
    }

    return (
        <tr>
            <td>'<b>{data.nameFound}</b>' to '<b>{data.nameReal}</b>'</td>
            <td><button className='EssentialButton' onClick={confirmed}>Confirm</button></td>
            <td><button className='EssentialButton' onClick={update}>Change ({alternative})</button></td>
        </tr>
    );
}

export default NameMapped;