import React, { useContext } from 'react';
import localize from '../../i18n/localize';
import LocalizedContext from '../../i18n/LocalizedContext';

import './OpponentBout.css';

const OpponentBout = props => {
    const bout = props.bout
    const lang = useContext(LocalizedContext);

    const result = bout.isWinner ? 'V' : 'D';
    var stage = '???';
    
    switch(bout.stage) {
        case 'pools':
            stage = localize(lang, 'pools');
            break;
        case 'de':
            stage = localize(lang, 'de');
            break;
        case 'repechage':
            stage = localize(lang, 'repechage');
            break;
        case 'final8':
            stage = localize(lang, 'de');
            break;
        case 'worldFinal64':
            stage = localize(lang, 'de');
            break;
        default:
    };

    return (
        <div>
            <div className='OpponentBout-main'>{bout.fencerScore} : {bout.opponentScore} {result} ({stage})</div>
            <div className='OpponentBout-details'>{bout.date} at {bout.tournament} ( {bout.event} )</div>
        </div>
    );
}

export default OpponentBout;
