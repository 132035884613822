import React, { useContext } from 'react';
import localize from '../i18n/localize';
import LocalizedContext from '../i18n/LocalizedContext';

import './AboutUs.css';

const Connecting = props => {

    const hide = props.hide;
    const lang = useContext(LocalizedContext);

    return (
        <div className='AboutUs' onClick={hide}>
            <div className='AboutUs-space'/>
            <div className='AboutUs-selector'>
                <div className='AboutUs-head'>
                    {localize(lang, 'aboutMe')}
                </div>
                <p>
                    {localize(lang, 'aboutMeDetail1')}
                </p>
                <p>
                    {localize(lang, 'aboutMeDetail2')}
                </p>
                <div className='AboutUs-head'>
                    {localize(lang, 'credits')}
                </div>
                <p>
                    {localize(lang, 'dataCollection')}
                </p>
                <p>
                    {localize(lang, 'privacy')}
                </p>
            </div>
        </div>
    );
}

export default Connecting;