import React from 'react';
import './FourBox.css';
import constants from './constants';

const FourBoxCounts = props => {

    const counts = props.counts;
    const names = props.names;
    const offset = props.offset;
    const i = props.i;

    const index = <text x={offset.x + constants.BOX_TEXT_X} y={constants.TEXT_VERTICAL_MARGIN} className='darkText'>({i})</text>

    const box1Start = offset.y - (counts[0] + counts[1]) * constants.BOX_HEIGHT;
    const box1Height = counts[0] * constants.BOX_HEIGHT;
    const box1 = counts[0] === 0 ? '' : <rect x={offset.x} y={box1Start} width={constants.BOX_WIDTH} height={box1Height} className='box1'/>;
    const text1 = counts[0] === 0 ? '' : <text x={offset.x + constants.BOX_TEXT_X} y={box1Start + constants.BOX_TEXT_Y} className='lightText'>{counts[0]} {names[0]}</text>

    const box2Start = offset.y - counts[1] * constants.BOX_HEIGHT;
    const box2Height = counts[1] * constants.BOX_HEIGHT;
    const box2 = counts[1] === 0 ? '' : <rect x={offset.x} y={box2Start} width={constants.BOX_WIDTH} height={box2Height} className='box2'/>;
    const text2 = counts[1] === 0 ? '' : <text x={offset.x + constants.BOX_TEXT_X} y={box2Start + constants.BOX_TEXT_Y} className='darkText'>{counts[1]} {names[1]}</text>

    const box3Height = counts[2] * constants.BOX_HEIGHT;
    const box3 = counts[2] === 0 ? '' : <rect x={offset.x} y={offset.y} width={constants.BOX_WIDTH} height={box3Height} className='box3'/>;
    const text3 = counts[2] === 0 ? '' : <text x={offset.x + constants.BOX_TEXT_X} y={offset.y + constants.BOX_TEXT_Y} className='lightText'>{counts[2]} {names[2]}</text>

    const box4Start = offset.y + counts[2] * constants.BOX_HEIGHT;
    const box4Height = counts[3] * constants.BOX_HEIGHT;
    const box4 = counts[3] === 0 ? '' : <rect x={offset.x} y={box4Start} width={constants.BOX_WIDTH} height={box4Height} className='box4'/>;
    const text4 = counts[3] === 0 ? '' : <text x={offset.x + constants.BOX_TEXT_X} y={box4Start + constants.BOX_TEXT_Y} className='darkText'>{counts[3]} {names[3]}</text>

    return (
        <g>
            {index}
            {box1}
            {text1}
            {box2}
            {text2}
            {box3}
            {text3}
            {box4}
            {text4}
        </g>
    )
}

export default FourBoxCounts;