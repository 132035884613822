import React, { useContext, useState, useCallback, useEffect } from 'react';

import './SnapshotDisplay.css';
import '../../checkout/CheckoutStep.css';
import '../../essentials/EssentialButton.css';

import localize from '../../i18n/localize';
import LocalizedContext from '../../i18n/LocalizedContext';
import Checkout from '../../checkout/Checkout';
import callBackend from '../../utils/callBackend';

const SnapshotDisplay = props => {
    const snapshots = props.snapshots;
    const fencer = props.fencer;
    const closeHandler = props.closeHandler;

    const [isCheckout, setCheckout] = useState(false);
    const [context, setContext] = useState({});
    const [price, setPrice] = useState('...');
    const lang = useContext(LocalizedContext);

    const fetchDataHandler = useCallback( () => {
        callBackend('products', { language: lang.languageCode, offering: 'b2cOffering' },
            result => { 
                if( result.isOk ) {
                    const product = result.data.products[0];
                    setPrice(product.price + ' ' + product.currency);
                }
            }
        );
      }, [lang]);
    
    useEffect(() => {
        fetchDataHandler();
    }, [fetchDataHandler, lang] );


    const seasons = snapshots.map( snap => { 
        return (
            <li key={snap.seriesPk} className='SnapshotDisplay'>
                <span className='SnapshotDisplay-name'>{snap.name}</span>
                {localize(lang, 'organizationName')}:<span className='SnapshotDisplay-text'>{localize(lang, snap.organization)}</span>
                {localize(lang, 'weapon')}:<span className='SnapshotDisplay-text'>{localize(lang, snap.weapon)}</span>
                <span
                    className='SnapshotDisplay-button'
                    onClick={() => {
                        setContext({
                            fencer: fencer,
                            snapshot: snap
                        });
                        setCheckout(true);
                    }}>{localize(lang, 'download')}</span>
            </li>
        ); } );

    const checkoutModal = isCheckout ?
        <Checkout flow='B2C' context={context} cancelHandler={() => setCheckout(false)} closeHandler={closeHandler} /> : '';

    const filename = '/terms/fencingStatistics-' + lang + '.pdf';

    return (
        <div>
            {checkoutModal}
            <div className='SnapshotDisplay-label'>
                {localize(lang, 'exportFencer')}
            </div>
            <div className='SnapshotDisplay-text'>
                {localize(lang, 'snapshotDownload')} <b>{fencer}</b>.
                <br/>
                <br/>
                {localize(lang, 'paidDownload', { '@price@': price })}
                <a className='CheckoutStep'
                    href={filename}
                    download='Sample.pdf'
                    target='_blank'
                    rel='noopener noreferrer'>{localize(lang, 'sampleDownload')}</a>
            </div>
            <div className='SnapshotDisplay-label'>
                {localize(lang, 'availableSeasons')}:
            </div>
            <ul className='SnapshotDisplay'>
                {seasons}
            </ul>
            <div className='SnapshotDisplay-text'>
                <center>
                    <button className='EssentialButton' onClick={closeHandler}>{localize(lang, 'backToFencer')}</button>
                </center>
            </div>
        </div>
    );
}

export default SnapshotDisplay;