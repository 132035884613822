import React, { useContext } from "react";
import { useDispatch, useSelector } from 'react-redux';
import localize from '../i18n/localize';
import LocalizedContext from '../i18n/LocalizedContext';
import { checkoutGoToStep } from './checkoutSlice';
import { INITIAL_STATE_CHECKOUT } from './checkoutSlice';
import './ProgressBar.css';

const ProgressBar = () => {
    const lang = useContext(LocalizedContext);
    const dispatch = useDispatch();

    const checkoutState = useSelector(state => state.checkout.step);
    const checkoutFlow = useSelector(state => state.checkout.flow);

    const goFiller =   '>';
    const doneFiller = '-';

    const makeBar = (currentState, showGrey, i, result) => {
        if( !currentState )
            return makeBar(INITIAL_STATE_CHECKOUT.step, false, 0, []);

        const step = showGrey ? 
                <span key={i} className='ProgressBar-grey'>{localize(lang, currentState)}</span> :
                <span onClick={ () => dispatch(checkoutGoToStep(currentState))}  key={i} className='ProgressBar'>{localize(lang, currentState)}</span>;

        const path = showGrey ? 
                <span key={i+1} className='ProgressBar-grey'>{goFiller}</span> :
                <span key={i+1} className='ProgressBar'>{doneFiller}</span>;
                
        const updatedResult = result.length ? [...result, path, step] : [...result, step];
        const nextShowGrey = showGrey || checkoutState === currentState;
        const nextStep = checkoutFlow[currentState];
        
        if( checkoutFlow[nextStep] )       // The last must be thank you page - not in the bar
            return makeBar( nextStep, nextShowGrey, i+2, updatedResult );
        else
            return updatedResult;
    }

    const bar = checkoutFlow ? makeBar() : '';

    return (
        <div className='ProgressBar'>
            {bar}
        </div>
    );
}

export default ProgressBar