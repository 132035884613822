import React, { useState, useContext } from 'react';
import localize from '../i18n/localize';
import LocalizedContext from '../i18n/LocalizedContext';

import './Footer.css';
import ContactUs from './ContactUs';
import AboutUs from './AboutUs';
import WhatsNew from './WhatsNew';

const Footer = () => {
    const [showingAbout, setShowingAbout] = useState(false);
    const [showingContact, setShowingContact] = useState(false);
    const [showingNew, setShowingNew] = useState(false);
    const lang = useContext(LocalizedContext);

    const showAbout = () => { setShowingAbout(true); }
    const showContact = () => { setShowingContact(true); }
    const showNew = () => { setShowingNew(true); }

    const hideAbout = () => { setShowingAbout(false); }
    const hideContact = () => { setShowingContact(false); }
    const hideNew = () => { setShowingNew(false); }

    if( showingAbout ) return( <AboutUs hide={hideAbout} />);
    if( showingContact ) return( <ContactUs hide={hideContact} />);
    if( showingNew ) return( <WhatsNew hide={hideNew} />);

    return (
        <div className="Footer">
            <div className="Footer-item" onClick={showNew}>{localize(lang, 'whatNew')}</div>
            <div className="Footer-item" onClick={showAbout}>{localize(lang, 'about')}</div>
            <div className="Footer-item" onClick={showContact}>{localize(lang, 'contact')}</div>
        </div>
    );
}

export default Footer;