import React, { useContext } from "react";
import { useDispatch, useSelector } from 'react-redux';
import localize from '../i18n/localize';
import LocalizedContext from '../i18n/LocalizedContext';
import { checkoutStep } from './checkoutSlice';

import './CheckoutStep.css';
import BottomBar from './BottomBar';
import callBackend from '../utils/callBackend';

// B2B
const CheckoutSummary = props => {
    const cancelCheckout = props.cancelCheckout;
    const lang = useContext(LocalizedContext);
    const dispatch = useDispatch();

    const checkoutData = useSelector(state => state.checkout);

    const submit = () => {
        callBackend('submit',
            { id: checkoutData.id, language: lang.languageCode }
        );
        dispatch(checkoutStep(''));
    };

    const product = useSelector(state => state.checkout.offering).find( offer => offer.id === checkoutData.product );

    return (
        <div className='CheckoutStep-content'>
            <div className='CheckoutStep'>
                <table>
                    <tbody>
                        <tr>
                            <td  className='CheckoutStep-summary'>{localize(lang, 'yourName')}:</td>
                            <td  className='CheckoutStep-summary'>{checkoutData.firstName + ' ' + checkoutData.lastName}</td>
                        </tr>
                        <tr>
                            <td  className='CheckoutStep-summary'>{localize(lang, 'email')}:</td>
                            <td  className='CheckoutStep-summary'>{checkoutData.email}</td>
                        </tr>
                        <tr>
                            <td  className='CheckoutStep-summary'>{localize(lang, 'organizationName')}:</td>
                            <td  className='CheckoutStep-summary'>{checkoutData.organizationName}</td>
                        </tr>
                        <tr>
                            <td  className='CheckoutStep-summary'>{localize(lang, 'competitionType')}:</td>
                            <td  className='CheckoutStep-summary'>{localize(lang, checkoutData.gender) + ' ' + localize(lang, checkoutData.weapon) + ' ' + localize(lang, checkoutData.competition) + ' ' + localize(lang, checkoutData.age)}</td>
                        </tr>
                        <tr>
                            <td  className='CheckoutStep-summary'>{localize(lang, 'product')}:</td>
                            <td  className='CheckoutStep-summary'>{product.description}</td>
                        </tr>
                        <tr>
                            <td  className='CheckoutStep-summary'>{localize(lang, 'price')}:</td>
                            <td  className='CheckoutStep-summary'>{product.price} {product.currency}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            {localize(lang, 'agreeWithTC')}
            <a className='CheckoutStep'
                href='/terms/tc.pdf'
                download='Terms-and-Conditions'
                target='_blank'
                rel='noreferrer'>{localize(lang, 'terms')}</a>.
            <BottomBar cancelCheckout={cancelCheckout} submitStep={submit} isSubmit={true} />
        </div>);
}

export default CheckoutSummary