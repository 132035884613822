import React, { useState } from 'react';

import './SelectedTournament.css';
import TournamentMgmt from './input/TournamentMgmt';
import RainbowMgmt from './input/RainbowMgmt';
import Direction from './input/Direction';
import OneSerie from '../tournament/series/OneSerie';

import callBackend from '../utils/callBackend';
import TournamentNameMgmt from './input/TournamentNameMgmt';

const SelectedTournament = props => {
    const tournament = props.selected;
    const changeTournamentHandler = props.changeTournamentHandler;
    const updateRainbowHandler = props.updateRainbowHandler;
    const setEventHandler = props.setEventHandler;

    const [tournamentCreated, setTournamentCreated] = useState(false);

    const newTournamentHandler = req => {
        setTournamentCreated( true );
        callBackend('createTournament', req);
    };

    const updateTourneamentHandler = req => {
        setTournamentCreated( false );
        changeTournamentHandler( req );
    };

    const previousTournamentHandler = () => {
        updateTourneamentHandler({returning: 'previous', tournamentPk: tournament.pk});
    }

    const nextTournamentHandler = () => {
        updateTourneamentHandler({returning: 'next', tournamentPk: tournament.pk});
    }

    return (
        <div>
            <div className='SelectedTournament-header'>
                Add Tournament
            </div>
            <TournamentMgmt submitHandler={newTournamentHandler}
                updateTourneamentHandler={updateTourneamentHandler}
                tournamentCreated = {tournamentCreated} />
            <div className='SelectedTournament-header'>
                Manage Tournament: {tournament.name}
            </div>
            <RainbowMgmt tournamentPk={tournament.pk}
                tournamentRainbow={tournament.rainbow}
                updateTourneamentHandler={updateTourneamentHandler}
                updateRainbowHandler={updateRainbowHandler} />
            <TournamentNameMgmt key={tournament.pk} tournament={tournament} />
            <div className='SelectedTournament-footer'>
                <Direction clickHandler={previousTournamentHandler} name='Previous' />
                <Direction clickHandler={nextTournamentHandler} name='Next' />
            </div>
            <OneSerie events={tournament.events} setEventHandler={setEventHandler} highlightEvent={true} />
        </div>
    );
}

export default SelectedTournament;