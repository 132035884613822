import React from 'react';

import './BarChart.css';

function BarChart({ data, barWidth, barHeight, isExempt }) {
  // Calculate the scale factor for the heights
  const scaleFactor = barHeight / 100;  // 100%
  const halfBarWidth = barWidth / 2;
  const xOffset = 60;
  const xOffsetAfter = 30;
  const yOffsetTop = 20;
  const yOffsetBottom = 20;

  // Generate the bars using the data array
  const bars = data.map((height, index) => {
    const className = (Math.floor(index / 16) % 2) === 0 ? 'BarChart-box1' : 'BarChart-box2';
    return <rect
      className={className}
      key={index}
      x={index * barWidth + xOffset}
      y={yOffsetTop + barHeight - height * scaleFactor}
      width={barWidth}
      height={height * scaleFactor}
    />;
  });

  const tops = data.map( (height, index) => (
    <circle
      className='BarChart-point'
      key={index}
      cx={index * barWidth + halfBarWidth + xOffset}
      cy={yOffsetTop + barHeight - height * scaleFactor}
      r={halfBarWidth}
    />
  ));

  const leftDescription = ['100', '75', '50', '25', '0'];
  const leftAxis = leftDescription.map( (leftValue, index) => (
    <text
      className='BarChart-text'
      key={index}
      x='5'
      y={yOffsetTop + index * (barHeight / (leftDescription.length-1)) } >
        {leftValue}%
    </text>
  ));

  const bottomAxis = data.map( (height, index) => {
    if( isExempt && index === 0 ) {
      return (
        <text
          className='BarChart-text'
          key={index}
          x={xOffset}
          y={yOffsetTop + barHeight + yOffsetBottom - 4} >
          exempt
        </text>
      );
    }

    const place = isExempt ? index - 15 : index + 1;
    if( ( place > 1 && place % 16) === 0 ) {
      return (
          <text
            className='BarChart-text'
            key={index}
            x={xOffset + index * barWidth - 6 }
            y={yOffsetTop + barHeight + yOffsetBottom - 4} >
            {place}
          </text>
        );
      } else if( place === 1 ) {
          return (
            <text
              key={index}
              x={xOffset + index * barWidth - 3 }
              y={yOffsetTop + barHeight + yOffsetBottom - 4} >
              {place}
            </text>
          );
      } else
        return '';
  });

  // Calculate the total width of the chart
  const chartWidth = data.length * barWidth + xOffset + xOffsetAfter;
  const chartHeight = barHeight + yOffsetTop + yOffsetBottom;

  return (
    <svg width={chartWidth} height={chartHeight}>
      <rect
       className='BarChart-background'
       width={chartWidth}
       height={chartHeight}
       rx='5' />
      {bars}
      {tops}
      {data.length > 0 ? leftAxis : ''}
      {bottomAxis}
    </svg>
  );
}

export default BarChart;

