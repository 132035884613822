import React from 'react';
import './Direction.css';

const Direction = props => {
    const clickHandler = props.clickHandler;
    const name = props.name;

    return (
        <button className='Direction' onClick={clickHandler}>{name}</button>
    );
}

export default Direction;