import React, { useContext } from "react";
import localize from '../i18n/localize';
import LocalizedContext from '../i18n/LocalizedContext';

import './BottomBar.css';

const BottomBar = props => {
    const cancelCheckout = props.cancelCheckout;
    const canSubmit = props.canSubmit;
    const submitStep = props.submitStep;
    const isSubmit = props.isSubmit;
    const dismiss = props.dismiss;
    const lang = useContext(LocalizedContext);

    if( dismiss ) {
        return (
            <div className='BottomBar'>
                <span className='BottomBar' onClick={cancelCheckout}>{localize(lang, 'accept')}</span>
            </div> );        
    }

    if( isSubmit ) {
        return (
            <div className='BottomBar'>
                <span className='BottomBar' onClick={cancelCheckout}>{localize(lang, 'cancel')}</span>
                <span className='BottomBar' onClick={submitStep}>{localize(lang, 'submit')}</span>
            </div> );
    }

    return (
        <div className='BottomBar'>
            <span className='BottomBar' onClick={cancelCheckout}>{localize(lang, 'cancel')}</span>
            { canSubmit ? 
                <span className='BottomBar' onClick={submitStep}>{localize(lang, 'next')}</span> :
                <span className='BottomBar-nope'>{localize(lang, 'next')}</span> }
        </div> );
}

export default BottomBar