const search = () => {

    let data = {};
    let all = new Set();

    const tokenize = term => {
        // Support for non [a-z] characters
        const components = term.toLowerCase().replaceAll(/\s/g, '').replaceAll(/[^a-z]/g, '*').split('');

        if( components.length === 0 ) return [];
        if( components.length === 1 ) return components;

        const parts = new Set();
        for( let i = 0; i < components.length - 1; i++ ) {
            if( components[i] !== '*' || components[i+1] !== '*' )   // Don't store '**'
                parts.add( components[i] + components[i+1] );
        }

        return [...parts];
    };

    const termResults = term => {
        if( term.includes('*') ) {
            return data[term];
        } else {
            const terms = [term, term.substring(0,1) + '*', '*' + term.substring(1,2)];
            const res = new Set();

            terms.forEach( t => {
                const content = data[t];
                if( content ) content.forEach( a => res.add(a) );
            });
            return res;
        }
    };

    const intersection = (term, results) => {
        if( term.length === 0 ) return results;
        const [head, ...tail] = term;

        const other = termResults(head);
        if( other === undefined || other.size === 0 ) {
            return [];
        } else {
            // First invocation
            if( results === undefined ) return intersection( tail, [...other] );

            const filtered = results.filter(el => other.has(el));
            return intersection(tail, filtered);
        }
    };

    const addTerm = term => {
        if( !all.has(term) ) {
            tokenize(term).forEach(letter => {
                if (data.hasOwnProperty(letter)) {
                    data[letter].add(term);
                } else {
                    data[letter] = new Set().add(term);
                }
            });
            all.add(term);
        }
    };

    const searchTerm = term => {
        if( term.length === 0 ) {
            return [...all].sort();
        } else {
            const res = intersection(tokenize(term));

            if( term.length === 1 && res.length === 0 ) return [...all].sort();
            else return res.sort();
        }
    };

    const clean = () => {
        data = {};
        all = new Set();
    };

    return { addTerm, searchTerm, clean };
}

export default search;