import { createSlice } from '@reduxjs/toolkit'

const YOU = 'YOU';
const TOURNAMENT = 'TOURNAMENT';
const STATS = 'STATS';
const PACKAGE = 'PACKAGE';
const B2C_PACKAGE = 'B2C_PACKAGE';
const SUMMARY = 'SUMMARY';
const B2C_SUMMARY = 'B2C_SUMMARY';
const THANK_YOU = 'THANK_YOU';
const B2C_THANK_YOU = 'B2C_THANK_YOU';

const CHECKOUT_FLOW_B2B = {
    YOU: TOURNAMENT,
    TOURNAMENT: STATS,
    STATS: PACKAGE,
    PACKAGE: SUMMARY,
    SUMMARY: THANK_YOU
};

const CHECKOUT_FLOW_B2C = {
    YOU: B2C_PACKAGE,
    B2C_PACKAGE: B2C_SUMMARY,
    B2C_SUMMARY: B2C_THANK_YOU
};

const INITIAL_STATE_CHECKOUT = {
    offering: [],
    flow: null,
    context: null,
    id: null,
    step: YOU,
    firstName: '',
    lastName: '',
    email: '',
    age: '',
    competition: '',
    gender: '',
    weapon: '',
    organizationName: '',
    product: ''
};

export const checkoutSlice = createSlice({
  name: 'checkout',

  initialState: INITIAL_STATE_CHECKOUT,

  reducers: {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes

    clear: () => INITIAL_STATE_CHECKOUT,

    addCheckoutFlow: (state, action) => {
        switch( action.payload ) {
            case 'B2B':
                state.flow = CHECKOUT_FLOW_B2B;
                break;
            case 'B2C':
                state.flow = CHECKOUT_FLOW_B2C;
                break;
            default:
                state.flow = null;
        }
    },

    addCheckoutId: (state, action) => {
        state.id = action.payload;
    },

    addCheckoutContext: (state, action) => {
        state.context = action.payload;
    },

    addOffering: (state, action) => {
        state.offering = action.payload;
    },

    checkoutStepYou: (state, action) => {
        state.step = state.flow[state.step];
        state.firstName = action.payload.firstName;
        state.lastName = action.payload.lastName;
        state.email = action.payload.email;
    },

    checkoutStepTournament: (state, action) => {
        state.step = state.flow[state.step];
        state.age = action.payload.age;
        state.competition = action.payload.competition;
        state.gender = action.payload.gender;
        state.weapon = action.payload.weapon;
    },

    checkoutStepStats: (state, action) => {
        state.step = state.flow[state.step];
        state.organizationName = action.payload.organizationName;
    },

    checkoutStepPackage: (state, action) => {
        state.step = state.flow[state.step];
        state.product = action.payload.product;
    },

    checkoutStep: state => {
        state.step = state.flow[state.step];
    },

    checkoutGoToStep: (state, action) => {
        state.step = action.payload;
    },
  }
})

// Action creators are generated for each case reducer function
export const { clear, addCheckoutFlow, addCheckoutId, addCheckoutContext, addOffering, checkoutStepYou, checkoutStepTournament, checkoutStepStats, checkoutStepPackage, checkoutStep, checkoutGoToStep } = checkoutSlice.actions
export { INITIAL_STATE_CHECKOUT }

export default checkoutSlice.reducer