import React, { useContext } from 'react';
import localize from '../../i18n/localize';
import LocalizedContext from '../../i18n/LocalizedContext';

import './FacetSearch.css';

// Stella time ...
const FacetSearch = props => {
    const max = props.max;
    const facets = props.facets;
    const filterFacetsHandler = props.filterFacetsHandler;
    const lang = useContext(LocalizedContext);

    const clazzName = (value, dim) => {
        if (value)
            return facets[dim].length === 1 ? 'FacetSearch-selected' : 'FacetSearch-option';
        else
            return 'FacetSearch-none';
    }

    const rows = [];
    for( let i = max; i >= 0; i-- ) {
        const age = facets.age.length > i ? facets.age[i] : '';
        const competition = facets.competition.length > i ? facets.competition[i] : '';
        const gender = facets.gender.length > i ? facets.gender[i] : '';
        const weapon = facets.weapon.length > i ? facets.weapon[i] : '';

        const ageDisplay = age ? localize(lang, age) : '';
        const competitionDisplay = competition ? localize(lang, competition) : '';
        const genderDisplay = gender ? localize(lang, gender) : '';
        const weaponDisplay = weapon ? localize(lang, weapon) : '';

        rows.push(
            <tr key={i}>
                <td className={clazzName(weapon, 'weapon')} key='1' onClick={ () => { if(weapon) filterFacetsHandler( { weapon }) } }>{weaponDisplay}</td>
                <td className={clazzName(gender, 'gender')} key='2' onClick={ () => { if(gender) filterFacetsHandler( { gender }) } }>{genderDisplay}</td>
                <td className={clazzName(competition, 'competition')} key='3' onClick={ () => { if(competition) filterFacetsHandler( { competition }) } }>{competitionDisplay}</td>
                <td className={clazzName(age, 'age')} key='4' onClick={ () => { if(age) filterFacetsHandler( { age }) } }>{ageDisplay}</td>
            </tr>
        );
    }

    return (
        <table className='FacetSearch'>
            <tbody>
                {rows}
            </tbody>
        </table>
    );
};

export default FacetSearch;