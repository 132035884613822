import React, { useState, useContext } from "react";
import { useSelector, useDispatch } from 'react-redux';
import localize from '../i18n/localize';
import LocalizedContext from '../i18n/LocalizedContext';
import { addCheckoutId, checkoutStepStats } from './checkoutSlice';

import './CheckoutStep.css';
import BottomBar from './BottomBar';
import callBackend from '../utils/callBackend';

const CheckoutStepStats = props => {
    const cancelCheckout = props.cancelCheckout;
    const lang = useContext(LocalizedContext);
    const dispatch = useDispatch();

    const [organizationName, setOrganizationName] = useState(useSelector(state => state.checkout.organizationName));
    const id = useSelector(state => state.checkout.id);

    const changeOrganizationNameHandler = event => {
        setOrganizationName(event.target.value);
    };

    const submitStep = () => {
        const DATA = { organizationName };
        dispatch(checkoutStepStats(DATA));
        callBackend('checkout',
            { id, stats: DATA }, 
            result => { if( result.isOk ) dispatch(addCheckoutId(result.data.id)); }
        );
    };

    const canSubmit = organizationName;

    return (
        <div className='CheckoutStep-content'>
            <div className='CheckoutStep'>
                <table className='CheckoutStep'>
                    <tbody>
                        <tr>
                            <td className='CheckoutStep'>{localize(lang, 'organizationName')}:</td>
                            <td className='CheckoutStep'><input className='CheckoutStep' type='text' onChange={changeOrganizationNameHandler} value={organizationName} /></td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <BottomBar cancelCheckout={cancelCheckout} canSubmit={canSubmit} submitStep={submitStep} />
        </div>);
}

export default CheckoutStepStats