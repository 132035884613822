import React, { useContext } from 'react';
import localize from '../../i18n/localize';
import LocalizedContext from '../../i18n/LocalizedContext';

import './Highlights.css';

const Highlights = props => {

    const highlight = props.highlights;
    const highlightedEvent = props.highlightedEvent;
    const setSelectedHighlightHandler = props.setSelectedHighlightHandler;
    const lang = useContext(LocalizedContext);

    const fencer = highlightedEvent ?
        <span className='Highlights' onClick={setSelectedHighlightHandler}>{highlight.fencer}</span>
        :    
        <span>{highlight.fencer}</span>;

    return (
        <div className='Highlights-envelope'>
            <div className='Highlights-header'>
                {localize(lang, 'todaysHighlight')} {fencer}
            </div>
            <div className='Highlights-text'>
                {highlight}
            </div>
        </div>
    );
}

export default Highlights;