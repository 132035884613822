import React, { useContext } from 'react';
import localize from '../../i18n/localize';
import LocalizedContext from '../../i18n/LocalizedContext';

import './BoutResult.css';

const BoutResult = props => {
    const fencerSelectedHandler = props.changeFencer;
    const bout = props.bout;
    const lang = useContext(LocalizedContext);

    if( bout.advancing ) {
        if( bout.stage === 'de') return <div className='BoutResult'>{localize(lang, 'advancing')}</div>;
        if( bout.stage === 'repechage') return <div className='BoutResult'>{localize(lang, 'waitingForRep')}</div>;
    }

    const boutDetail = bout.hasScore ? bout.scoreMe + ':' + bout.scoreOpponent : (bout.victory ? 'opponent withdrew' : 'withdrawal');
    const ctw = bout.ctw ? ' (ctw: ' + bout.ctw + '%)' : '';
    const vd = bout.victory ? 'V' : 'D';

    return (
        <div className='BoutResult' onClick={ () => fencerSelectedHandler(bout.opponent) }>
            vs. <span className='BoutResult'>{bout.opponent}</span> {boutDetail} {vd}{ctw}
        </div>
    );
}

export default BoutResult;