import React, { useState } from 'react';

import './Translations.css';
import callBackend from '../utils/callBackend';
import enDict from '../i18n/en.json';

const Translations = () => {
    const [adjective, setAdjective] = useState('');
    const [feName, setFeName] = useState('');
    const [channel, setChannel] = useState('');
    const [language, setLanguage] = useState('');
    const [code, setCode] = useState('');
    const [fix, setFix] = useState('');


    const changeAdjectiveHandler = event => {
        setAdjective(event.target.value);
    };

    const changeFeNameHandler = event => {
        setFeName(event.target.value);
    };

    const clickAddLanguageHandler = () => {
        const payload = { feName, adjective };
        callBackend('addLanguage', payload, () => {
            setAdjective('');
            setFeName('');
        });
    };

    const clickTranslateHandler = () => {
        callBackend('translate', {data: enDict, channel: 'stats'});
    };

    const changeLanguageHandler = event => {
        setLanguage(event.target.value);
    };

    const changeCodeHandler = event => {
        setCode(event.target.value);
    };

    const changeFixHandler = event => {
        setFix(event.target.value);
    };

    const changeChannelHandler = event => {
        setChannel(event.target.value);
    };

    const clickFixTranslationHandler = () => {
        const payload = { language, code, fix, channel };
        callBackend('fixTranslation', payload, () => {
            setCode('');
            setFix('');
        });
    };

    const clickHighlightsHandler = () => {
        callBackend('makeHighlights', null);
    };

    return (
        <div className='Translations'>
            <div className='Translations-section'>
                Refresh highlights: <button className='Translations' onClick={clickHighlightsHandler}>Click Once</button>
            </div>
            <div className='Translations-section'>
                Adjective for GPT: <input className='Translations Translations-short'
                    type='text'
                    onChange={changeAdjectiveHandler}
                    value={adjective} />

                FE Display: <input className='Translations Translations-short'
                    type='text'
                    onChange={changeFeNameHandler}
                    value={feName} />

                <button className='Translations' onClick={clickAddLanguageHandler}>Add Language</button>
            </div>
            <div className='Translations-section'>
                Translate: <button className='Translations' onClick={clickTranslateHandler}>Click Once</button>
            </div>
            <div className='Translations-section'>
                Language for GPT: <input className='Translations Translations-short'
                    type='text'
                    onChange={changeLanguageHandler}
                    value={language} />

                Channel: <input className='Translations Translations-short'
                    type='text'
                    onChange={changeChannelHandler}
                    value={channel} />

                Code: <input className='Translations Translations-short'
                    type='text'
                    onChange={changeCodeHandler}
                    value={code} />

                Fix: <input className='Translations Translations-long'
                    type='text'
                    onChange={changeFixHandler}
                    value={fix} />

                <button className='Translations' onClick={clickFixTranslationHandler}>Fix Translation</button>
            </div>
        </div>
    );
}

export default Translations;