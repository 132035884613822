import React, { useState, useEffect, useCallback, useContext } from 'react';
import localize from '../../i18n/localize';
import LocalizedContext from '../../i18n/LocalizedContext';

import './SeriesSummary.css';
import Connecting from '../../dataStatus/Connecting';
import BarChart from './graphs/BarChart';
import PointChart from './graphs/PointChart';
import SimpleBarChart from './graphs/SimpleBarChart';

import hashMaker from '../../utils/hashMaker';
import callBackend from '../../utils/callBackend';
import '../../essentials/EssentialButton.css';

const SeriesSummary = props => {
    const series = props.series;
    const lang = useContext(LocalizedContext);

    const [isLoading, setIsLoading] = useState(true);
    const [summaryData, setSummaryData] = useState(null);
    const [lastAdvancingAnalysis, setLastAdvancingAnalysis] = useState(null);
    const [lastExemptAnalysis, setLastExemptAnalysis] = useState(null);
    const [selectedTarget, setSelectedTarget] = useState('');

    const fetchDataHandler = useCallback( () => {
        setIsLoading(true);
        callBackend('seriesHighlights', { checksum: hashMaker(series.seriesPk + series.weapon), seriesPk: series.seriesPk }, result => {
            if( result.isOk ) {
                setSummaryData(result.data.data);
                setLastAdvancingAnalysis(result.data.lastAdvancingAnalysis);
                setLastExemptAnalysis(result.data.lastExemptAnalysis);
            }
            setIsLoading(false);
        })
    }, [series]);
    
    useEffect(() => {
        fetchDataHandler();
    }, [fetchDataHandler, series] );

    const targetList = () => {
        if( !summaryData || !summaryData.pool ) return [];

        const targets = Object.keys(summaryData.pool);
        return [...targets];
    };

    const targetButtons = targetSize => {
        return targetSize.map( ts => {
            const cssClass = ts === selectedTarget ? 'EssentialButton-selected' : 'EssentialButton';
            return (
                <button key={ts} className={cssClass} onClick={ () => setSelectedTarget(ts) } >
                    {ts}
                </button>
            );
        });
    };

    const calculatePctage = o => {
        const all = o.r + o.m;
        if( all === 0 ) return 0;   // Total edge case, but possible
        else return Math.round((o.r * 100) / all);
    }

    const makeValues = graph => {
        if( ! selectedTarget ) return [];

        const values = summaryData.exempt[selectedTarget][graph].concat(summaryData.pool[selectedTarget][graph])
        return values.map(calculatePctage);
    }

    const isExempt = () => {
        return summaryData.exempt['8'].successRate.length > 0;
    }

    const display = selectedTarget ?
        <div>
            <div className='SeriesSummary-text'>
                <BarChart data={makeValues('successRate')} barWidth={4} barHeight={120} isExempt={isExempt()} />
            </div>
            <div className='SeriesSummary-text'>
                {localize(lang, 'series1')}.
                <br/>
                {localize(lang, 'series2')} {selectedTarget}.&nbsp;
            </div>
            <div className='SeriesSummary-headline'>
                {localize(lang, 'successIn')}
            </div>
            <div className='SeriesSummary-text'>
                {localize(lang, 'sameAs')}
            </div>
            <div className='SeriesSummary-text'>
                <BarChart data={makeValues('smooth')} barWidth={4} barHeight={120} isExempt={isExempt()} />
            </div>
        </div> : '';

    const advance = !isLoading && (summaryData.lastAdvancing.length > 0) ?
        <div>
            <div className='SeriesSummary-headline'>
                {localize(lang, 'lastFencerDE')}
            </div>
            <div className='SeriesSummary-text'>
                {localize(lang, 'dependingOnPct')}.
            </div>
            <div className='SeriesSummary-text'>
                <PointChart
                    data={summaryData.lastAdvancing} metaData={lastAdvancingAnalysis}
                    width={400} height={120}
                    xAxis={ o => o.notAdvancing } suffix='%' />
            </div>
        </div> : '';

    const direct = !isLoading && (summaryData.lastExempt.length > 0) ?
        <div>
            <div className='SeriesSummary-headline'>
                {localize(lang, 'lastFencerTop')}
            </div>
            <div className='SeriesSummary-text'>
                {localize(lang, 'dependingOntNum')}.
            </div>
            <div className='SeriesSummary-text'>
                <PointChart
                    data={summaryData.lastExempt} metaData={lastExemptAnalysis}
                    width={400} height={120}
                    xAxis={ o => o.totalFencers } suffix='.' />
            </div>
        </div> : '';

    const boutV5Count = !isLoading && summaryData && summaryData.boutCount.pools ? 
        <div>
            <div className='SeriesSummary-headline'>
                {localize(lang, 'typicalScorePools')}
            </div>
            <div className='SeriesSummary-text'>
                {localize(lang, 'verticalShows')}
            </div>
            <div className='SeriesSummary-text'>
                <SimpleBarChart data={summaryData.boutCount.pools} barWidth={80} barHeight={120} prefix='5:' />
            </div>
        </div> : '';

    const boutV15Count = !isLoading && summaryData.boutCount.de ? 
        <div>
            <div className='SeriesSummary-headline'>
                {localize(lang, 'typicalScoreDE')}
            </div>
            <div className='SeriesSummary-text'>
                {localize(lang, 'verticalShows')}
            </div>
            <div className='SeriesSummary-text'>
                <SimpleBarChart data={summaryData.boutCount.de} barWidth={35} barHeight={120} prefix='15:' />
            </div>
        </div> : '';

    const boutV10Count = !isLoading && summaryData && summaryData.boutCount.deVet && !boutV15Count ? 
        <div>
            <div className='SeriesSummary-headline'>
                {localize(lang, 'typicalScoreDE10')}
            </div>
            <div className='SeriesSummary-text'>
                {localize(lang, 'verticalShows')}
            </div>
            <div className='SeriesSummary-text'>
                <SimpleBarChart data={summaryData.boutCount.deVet} barWidth={45} barHeight={120} prefix='10:' />
            </div>
        </div> : '';

    const content = isLoading ? <Connecting /> : 
        <div>
            <div className='SeriesSummary-headline'>
                {localize(lang, 'successOfFencers')}
            </div>
            <div className='SeriesSummary-text'>
                {localize(lang, 'chooseDE')}:
            </div>
            {targetButtons(targetList())}
            {display}
            {direct}
            {advance}
            {boutV5Count}
            {boutV10Count}
            {boutV15Count}
        </div>;

    return (
        <div>
            <div className='SeriesSummary-label'>
                {localize(lang, series.organization)} {localize(lang, series.gender)} {localize(lang, series.weapon)} Statistics
            </div>
            {content}
        </div>
    );    
}

export default SeriesSummary;