const constants = {
    WIDTH: 1000,
    HORIZONTAL_MARGIN: 120,
    LINE_HORIZONTAL_MARGIN: 10,
    VERTICAL_MARGIN: 40,
    TEXT_VERTICAL_MARGIN: 20,
    BOX_HEIGHT: 40,
    BOX_WIDTH: 90,
    BOX_SPACE: 20,
    BOX_TEXT_X: 5,
    BOX_TEXT_Y: 30,
    BASE_LINE_COLOR: '#D75B66',
    BASE_LINE_WIDTH: 3,

    GARTNER_POINT: 10,
    GARTNER_HOT_POINT: 20,
    GARTNER_TEXT_OFFSET: 20
}

export default constants;