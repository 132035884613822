import React, { useContext } from 'react';
import localize from '../i18n/localize';
import LocalizedContext from '../i18n/LocalizedContext';

import './WhatsNew.css';

const WhatsNew = props => {

    const hide = props.hide;
    const lang = useContext(LocalizedContext);

    return (
        <div className='WhatsNew' onClick={hide}>
            <div className='WhatsNew-space'/>
            <div className='WhatsNew-selector'>

            <div className='WhatsNew-head'>
                <span role='img' aria-label='new'>🆕</span> {localize(lang, 'releaseHeader1')}
            </div>
            <div className='WhatsNew-content'>
                {localize(lang, 'releaseDetail1')}
            </div>

            <div className='WhatsNew-head'>
                {localize(lang, 'releaseHeader2')}
            </div>
            <div className='WhatsNew-content'>
                {localize(lang, 'releaseDetail2')}
            </div>

            <div className='WhatsNew-head'>
                {localize(lang, 'releaseHeader3')}
            </div>
            <div className='WhatsNew-content'>
                {localize(lang, 'releaseDetail3')}
            </div>

            <div className='WhatsNew-head'>
                {localize(lang, 'releaseHeader4')}
            </div>
            <div className='WhatsNew-content'>
                {localize(lang, 'releaseDetail4')}
            </div>
            </div>
        </div>
    );
}

export default WhatsNew;