import React from 'react';
import './FencerMatches.css';
import './TeamSummary.css';

const FencerMatchSummary = props => {
    const appearance = props.appearance;
    const fencer = props.fencer;
    const opponents = props.opponents;

    const diffValue = d => {
        if( d > 0 ) return '+' + d;
        else return d;
    }

    const opponentsSummary = opponents.map( f =>
         <li className='FencerMatches-item' key={f.name} >
            {f.name}&nbsp;&nbsp;&nbsp;{f.scoreMe} : {f.scoreOpponent} ({diffValue(f.diff)})
         </li>
    );

    return (
        <div className='FencerMatches-header'>
            <div className={appearance(fencer.scoreMe, fencer.scoreOpponent, fencer.diff)}>
                {fencer.name}&nbsp;&nbsp;&nbsp;{fencer.scoreMe} : {fencer.scoreOpponent} ({diffValue(fencer.diff)})
            </div>
            <ol className='FencerMatches-list'>
                {opponentsSummary}
            </ol>
        </div>
    );
};

export default FencerMatchSummary;