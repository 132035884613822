const constants = {
    HEIGHT: 500,
    WIDTH: 1000,
    HORIZONTAL_MARGIN: 20,
    BEZIER_VS_GAP_RATIO: 3,
    THIN_LINE_WIDTH: 1,
    THICK_LINE_WIDTH: 5,
    COMMON_LINE_COLOR: 'black',
    PICKED_LINE_COLOR: '#D75B66',
}

export default constants;