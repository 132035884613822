import React from 'react';
import './TeamMatch.css';
import FencerMatches from './FencerMatches';

const TeamMatch = props => {
    const appearance = props.appearance;
    const opposingTeam = props.opposingTeam;
    const ourFencers = props.ourFencers;
    const teamSelectedHandler = props.teamSelectedHandler;

    const fencerMatchList = ourFencers.map( f =>
        <FencerMatches key={f.focusedFencer.name} fencer={f.focusedFencer} opponents={f.opponents} appearance={appearance} />
    );

    return (
        <div>
            <div className='TeamMatch-line' onClick={ () => teamSelectedHandler(opposingTeam.name) }>
                <span className='TeamMatch-text'>{opposingTeam.name}</span>&nbsp;&nbsp;{opposingTeam.scoreMe} : {opposingTeam.scoreOpponent}
            </div>
            {fencerMatchList}
        </div>
    );
};

export default TeamMatch;