import React from 'react';
import './FencerLine.css';

import constants from './constants';

const FencerLine = props => {
    const resultCount = props.resultCount;
    const width = props.selected ? constants.THICK_LINE_WIDTH : constants.THIN_LINE_WIDTH;
    const stroke = props.selected ? constants.PICKED_LINE_COLOR : constants.COMMON_LINE_COLOR;

    const verticalGap = constants.HEIGHT / (props.lineCount + 2);
    const horizontalGap = (constants.WIDTH - 2 * constants.HORIZONTAL_MARGIN) / resultCount;

    const vertical = standings => Math.round( standings * verticalGap );
    const horizontal = index => Math.round( constants.HORIZONTAL_MARGIN + index * horizontalGap );
    const bezier = Math.round( horizontalGap / constants.BEZIER_VS_GAP_RATIO );

    const standings = props.standings;

    var currentVertical = vertical( standings[0] );
    var currentHorizontal = horizontal( 0 );

    var collector = 'M ' + currentHorizontal + ' ' + currentVertical;

    for(var index = 1; index < resultCount; index++) {
        collector += ' C ' + (currentHorizontal + bezier) + ' ' + currentVertical;

        if( index < standings.length )
            currentVertical = vertical( standings[index] );
        currentHorizontal = horizontal( index );

        collector += ', ' + (currentHorizontal - bezier) + ' ' + currentVertical;
        collector += ', ' + currentHorizontal + ' ' + currentVertical;
    }

    return <path stroke={stroke} fill='transparent' strokeWidth={width} d={collector}/>
}

export default FencerLine;