const tournamentName = ep => {
    const e = ep.eventPlace;

    if( e.manCity ) {
        const level = e.manLevel ? e.manLevel + ' ' : '';
        const name = e.manName ? ' (' + e.manName + ')' : '';
        return level + e.manCity + name;
    } else {
        return e.tournamentName;
    }
};

const eventName = ep => {
    const e = ep.eventPlace;
    return e.eventName;
};

const eventDate = ep => {
    const e = ep.eventPlace;
    if( e.eventIsoDate )
        return e.eventIsoDate;
    else
        return '';
};

const eventKey = ep => {
    const e = ep.eventPlace;
    return e.eventName + e.tournamentName;
}

export { tournamentName, eventName, eventDate, eventKey };