import React from 'react';
import './FourBox.css';
import constants from './constants';

const FourBoxYesNo = props => {

    const yesNo = props.yesNo;
    const names = props.names;
    const offset = props.offset;
    const i = props.i;

    const index = <text x={offset.x + constants.BOX_TEXT_X} y={constants.TEXT_VERTICAL_MARGIN} className='darkText'>({i})</text>

    const boxHeight = constants.BOX_HEIGHT;

    const box1Start = offset.y - 2 * boxHeight;
    const box2Start = offset.y - boxHeight;
    const box4Start = offset.y + boxHeight;

    return (
        <g>
            {index}
            <rect x={offset.x} y={box1Start} width={constants.BOX_WIDTH} height={boxHeight} className={yesNo[0] ? 'box1' : 'box4'}/>
            <rect x={offset.x} y={box2Start} width={constants.BOX_WIDTH} height={boxHeight} className={yesNo[1] ? 'box1' : 'box4'}/>
            <rect x={offset.x} y={offset.y} width={constants.BOX_WIDTH} height={boxHeight} className={yesNo[2] ? 'box1' : 'box4'}/>
            <rect x={offset.x} y={box4Start} width={constants.BOX_WIDTH} height={boxHeight} className={yesNo[3] ? 'box1' : 'box4'}/>
            <text x={offset.x + constants.BOX_TEXT_X} y={box1Start + constants.BOX_TEXT_Y} className={yesNo[0] ? 'lightText' : 'darkText'}>{names[0]}</text>
            <text x={offset.x + constants.BOX_TEXT_X} y={box2Start + constants.BOX_TEXT_Y} className={yesNo[1] ? 'lightText' : 'darkText'}>{names[1]}</text>
            <text x={offset.x + constants.BOX_TEXT_X} y={offset.y + constants.BOX_TEXT_Y} className={yesNo[2] ? 'lightText' : 'darkText'}>{names[2]}</text>
            <text x={offset.x + constants.BOX_TEXT_X} y={box4Start + constants.BOX_TEXT_Y} className={yesNo[3] ? 'lightText' : 'darkText'}>{names[3]}</text>
        </g>
    )
}

export default FourBoxYesNo;