import React from 'react';
import './TabHeader.css';

const TabHeader = props => {
    return (
        <div>
            <div className='TabHeader-header'>Managing Events</div>
            <div className='TabHeader-options'>
                {props.children}
            </div>
        </div>
    );
}

export default TabHeader;