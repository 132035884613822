import React, { useContext, useState } from 'react';
import localize from '../../i18n/localize';
import LocalizedContext from '../../i18n/LocalizedContext';

import './Series.css';
import FacetSearch from './FacetSearch';
import OrganizationPicker from './OrganizationPicker';

const Series = props => {
    const series = props.series;
    const setSeriesHandler = props.setSeriesHandler;
    const previousSeries = props.previousSeries;
    const organization = props.organization;
    const organizations = props.organizations;
    const setOrganizationHandler = props.setOrganizationHandler;

    const children = props.children;
    const lang = useContext(LocalizedContext);

    const [filteredSeries, setFilteredSeries] = useState(series);

    const facets = allSeries => {
        const age = [...new Set(allSeries.map( s => s.age ))];
        const competition = [...new Set(allSeries.map( s => s.competition ))];
        const gender = [...new Set(allSeries.map( s => s.gender ))];
        const organization = [...new Set(allSeries.map( s => s.organization ))];
        const weapon = [...new Set(allSeries.map( s => s.weapon ))];
        return {age, competition, gender, organization, weapon};
    };

    const getMax = fcts => {
        let max = 1;
        for( const key in fcts ) {
            const len = fcts[key].length;
            if( len > max ) max = len;
        }
        return max;
    }
    const max = getMax(facets(series)) - 1;

    const setSeries = series => {
        setFilteredSeries(series);
        if( series.length === 1 )
            setSeriesHandler( series[0] );
        else 
            setSeriesHandler( null );
    }

    const cleanFacetsHandler = () => {
        setSeries(series);
    };

    const filterFacetsHandler = facetValues => {
        let seriesInProgress = filteredSeries;
        for( const key in facetValues ) {
            seriesInProgress = seriesInProgress.filter( s => s[key] === facetValues[key] );
        }
        setSeries(seriesInProgress);
    };

    const seriesToLabel = s => {
        const organizationDisplay = localize(lang, s.organization);
        const weaponDisplay = localize(lang, s.weapon);
        const genderDisplay = localize(lang, s.gender);
        const competitionDisplay = localize(lang, s.competition);
        const ageDisplay = localize(lang, s.age);
        return organizationDisplay + ' ' + weaponDisplay + ' ' + genderDisplay + ' ' + competitionDisplay + ' ' + ageDisplay;
    }

    const selectSeriesHandler = () => {
        setSeries([previousSeries]);
    }

    const previousSearch = previousSeries ? 
        <span className='Series-info'>
            ({localize(lang, 'previously')}
            <span className='Series-text' onClick={selectSeriesHandler}>{seriesToLabel(previousSeries)}</span>)
        </span> : '';

    const clearEvent = 
        <div className='Series-clean' onClick={cleanFacetsHandler}>
            <span className='Series-text'>{localize(lang, 'clearEvent')}</span>
        </div>;

    return (
        <div className='Series'>
            <div className='Series-header'>
                {localize(lang, 'searchEvent')}
                {previousSearch}
            </div>
            <OrganizationPicker
                availableOrganizations={organizations}
                currentOrganization={organization}
                organizationHandler={setOrganizationHandler} />
            <FacetSearch
                max={max}
                facets={facets(filteredSeries)}
                filterFacetsHandler={filterFacetsHandler} />
            {clearEvent}
            {children}
        </div>
    );
}

export default Series;