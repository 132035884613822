import React, { useContext } from 'react';

import localize from '../../i18n/localize';

import LocalizedContext from '../../i18n/LocalizedContext';

import './FinalResults.css';

const OFFSET = 20;
const BOX_WIDTH = 300;
const BOX_HEIGHT = 150;

const makePlacementGraph = (values, minValue, maxValue) => {
    const valuesCount = values.length;

    // step in [0 .. valuesCount - 1]
    const getX = step => {
        const STEP_X = (BOX_WIDTH - 2 * OFFSET) / (valuesCount + 2);    // #values + edges
        return Math.round(STEP_X * (1 + step) + OFFSET);
    };

    const getY = value => {
        const STEP_Y = (BOX_HEIGHT - 2 * OFFSET) / (maxValue - minValue);
        const yBeforeInverse = Math.round(STEP_Y * (value - minValue) ) + OFFSET;
        return BOX_HEIGHT - yBeforeInverse;
    };

    const linesY = [
        minValue,
        0.2 * (maxValue - minValue) + minValue,
        0.4 * (maxValue - minValue) + minValue,
        0.6 * (maxValue - minValue) + minValue,
        0.8 * (maxValue - minValue) + minValue,
        (maxValue - minValue) + minValue
      ];

    const lines = linesY.map( (y, index) => {
        return (
            <line
                key={ index }
                x1={ getX( -1 ) }
                y1={ getY( y ) }
                x2={ getX( valuesCount ) }
                y2={ getY( y ) }
                stroke='#BBB'
                strokeWidth='1'
                strokeDasharray='2 4'
            />);
    });

    const leftLegend = linesY.map( (y, index) => {
       return (
            <text
                key={ index }
                x='0'
                y={ getY( y ) }
                className='FinalResults-text'
            >{ y }</text>); 
    });

    const points = values.map( (p, index) => {
        return (
            <circle
                key={ index }
                cx={ getX( index ) }
                cy={ getY( p ) }
                r='2'
                fill='#D75B66'
            />);
    });

    const lineMaker = values => {
        if( values.length < 2 ) return '';

        var currentHorizontal = getX( 0 );
        var currentVertical = getY( values[0] );
        const bezier = 2;
    
        var collector = 'M ' + currentHorizontal + ' ' + currentVertical;
    
        for(var index = 1; index < values.length; index++) {
            collector += ' C ' + (currentHorizontal + bezier) + ' ' + currentVertical;
    
            currentVertical = getY( values[index] );
            currentHorizontal = getX( index );
    
            collector += ', ' + (currentHorizontal - bezier) + ' ' + currentVertical;
            collector += ', ' + currentHorizontal + ' ' + currentVertical;
        }
    
        return <path stroke='#D75B66' fill='transparent' strokeWidth='2' d={collector}/>
    }

    const svgDimensions = '0 0 ' + BOX_WIDTH + ' ' + BOX_HEIGHT;

    return (
        <svg viewBox={svgDimensions}>
            {lines}
            {leftLegend}
            {points}
            {lineMaker(values)}
        </svg>
    );
}

// because we have 4 lines in the middle
const computeMax = elos => {
    const max = Math.max( ...elos );
    const newMax = (Math.floor(max / 5) + 1) * 5;
    return newMax;
}

const computeMin = elos => {
    const min = Math.min( ...elos );
    const newMin = Math.floor(min / 5) * 5;
    return newMin;
}

const Elo = props => {
    const elos = props.elos;
    const lang = useContext(LocalizedContext);

    if( elos && elos.length > 0 ) {
        const max = computeMax( elos );
        const min = computeMin( elos );
        const graph = makePlacementGraph( elos, min, max );

        return (
            <div>
                <div className='FinalResults-label'>{localize(lang, 'eloProgress')}</div>
                <div className='FinalResults-description'>{localize(lang, 'eloProgressDesc')}:</div>
                {graph}
            </div> );
    } else {
        return '';
    }
}

export default Elo;