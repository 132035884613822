import React from 'react';
import './TabItem.css';

const TabItem = props => {
    const name = props.isSelected ? 'TableItem-selectedTab' : 'TableItem-ordinaryTab';

    return (
        <span className={name} onClick={props.selectionHandler} >
            {props.children}
        </span>
    );
}

export default TabItem;