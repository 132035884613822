import React from 'react';
import { useContext } from 'react';
import localize from '../i18n/localize';
import LocalizedContext from '../i18n/LocalizedContext';

import './NoData.css';

const Connecting = () => {
    const lang = useContext(LocalizedContext);
    return (
        <div className='NoData'>
            <br/>
            <br/>
            {localize(lang, 'loading')}
        </div>
    );
}

export default Connecting;