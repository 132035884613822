import React, { useState } from 'react';

import './RainbowMgmt.css';

const RainbowMgmt = props => {
    const tournamentPk = props.tournamentPk;
    const tournamentRainbow = props.tournamentRainbow;
    const updateTourneamentHandler = props.updateTourneamentHandler;
    const updateRainbowHandler = props.updateRainbowHandler;

    const [tournamentPkValue, setTournamentPkValue] = useState(tournamentPk || 0);
    const [rainbowValue, setRainbowValue] = useState(tournamentRainbow || '');

    if( tournamentPk !== tournamentPkValue ) {
        setTournamentPkValue(tournamentPk);
        setRainbowValue(tournamentRainbow || '');
    }

    const changeRainbowValueHandler = event => {
        setRainbowValue(event.target.value);
    };

    const clickSearchHandler = () => {
        updateTourneamentHandler({returning: 'rainbow', rainbow: rainbowValue});
    };

    const clickUpdateHandler = () => {
        updateRainbowHandler({rainbow: rainbowValue, tournamentPk});
    };

    const isSearch = () => {
        return rainbowValue && (!tournamentPk || rainbowValue !== tournamentRainbow);
    };

    const isUpdate = () => {
        return rainbowValue && tournamentPk && rainbowValue !== tournamentRainbow;
    };

    return (
        <div className='RainbowMgmt'>
            Access key: <input className='RainbowMgmt'
                type='text'
                onChange={changeRainbowValueHandler}
                value={rainbowValue} />
            { isSearch() ? <button className='RainbowMgmt' onClick={clickSearchHandler}>Search Tournament</button> : '' }
            { isUpdate() ? <button className='RainbowMgmt' onClick={clickUpdateHandler}>Update Key</button> : '' }
        </div>
    );
}

export default RainbowMgmt;