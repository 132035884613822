import React, { useContext, useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import localize from '../i18n/localize';
import LocalizedContext from '../i18n/LocalizedContext';
import { clear, addCheckoutFlow, addCheckoutContext } from './checkoutSlice';

import './Checkout.css';

import Logo from '../headerFooter/Logo';
import CheckoutStepYou from './CheckoutStepYou';
import CheckoutStepTournament from './CheckoutStepTournament';
import CheckoutStepStats from './CheckoutStepStats';
import CheckoutStepPackage from './CheckoutStepPackage';
import CheckoutStepPackage2 from './CheckoutStepPackage2';
import CheckoutSummary from './CheckoutSummary';
import CheckoutSummary2 from './CheckoutSummary2';
import ProgressBar from './ProgressBar';
import CheckoutThankYou from './CheckoutThankYou';
import CheckoutThankYou2 from './CheckoutThankYou2';

const Checkout = props => {
    const cancelHandler = props.cancelHandler;
    const closeHandler = props.closeHandler;
    const flow = props.flow;
    const context = props.context;

    const lang = useContext(LocalizedContext);
    const currentStep = useSelector(state => state.checkout.step);

    const dispatch = useDispatch();

    const setupFlowHandler = useCallback( () => {
        dispatch(addCheckoutFlow(flow));
        if( context ) dispatch(addCheckoutContext(context));
    }, [dispatch, flow, context]);

    useEffect(() => {
        setupFlowHandler();
    }, [setupFlowHandler, dispatch, flow, context] );

    const cancelCheckout = () => {
        dispatch(clear());
        cancelHandler();
    };

    const closeCheckout = () => {
        cancelCheckout();
        closeHandler();
    }

    var currentCheckout = '';
    switch (currentStep) {
        case 'YOU':
            currentCheckout = <CheckoutStepYou cancelCheckout={cancelCheckout}/>;
            break;
        case 'TOURNAMENT':
            currentCheckout = <CheckoutStepTournament cancelCheckout={cancelCheckout}/>;
            break;
        case 'STATS':
            currentCheckout = <CheckoutStepStats cancelCheckout={cancelCheckout}/>;
            break;
        case 'PACKAGE':
            currentCheckout = <CheckoutStepPackage cancelCheckout={cancelCheckout}/>;
            break;
        case 'B2C_PACKAGE':
            currentCheckout = <CheckoutStepPackage2 cancelCheckout={cancelCheckout}/>;
            break;
        case 'SUMMARY':
            currentCheckout = <CheckoutSummary cancelCheckout={cancelCheckout}/>;
            break;
        case 'B2C_SUMMARY':
            currentCheckout = <CheckoutSummary2 cancelCheckout={cancelCheckout}/>;
            break;
        case 'THANK_YOU':
            currentCheckout = <CheckoutThankYou cancelCheckout={cancelCheckout}/>;
            break;
        case 'B2C_THANK_YOU':
            currentCheckout = <CheckoutThankYou2 cancelCheckout={closeCheckout}/>;
            break;
        default:
    }

    const isThankYou = currentStep === 'THANK_YOU' || currentStep === 'B2C_THANK_YOU';
    const isSummary = currentStep === 'SUMMARY' || currentStep === 'B2C_SUMMARY' || isThankYou;

    return (
        <div className='Checkout'>
            <div className='Checkout-selector'>
                <Logo clearContentHandler={() => {}} />
                <div className='Checkout-content'>
                    <div className='Checkout-header'>
                        {localize(lang, 'orderForm')}
                    </div>
                    { isSummary ? '' : localize(lang, 'addEvents')}
                </div>
                { isThankYou ? '' : <ProgressBar/> }
                {currentCheckout}
            </div>
        </div> );
}

export default Checkout;