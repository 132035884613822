import React, { useContext } from 'react';
import localize from '../../i18n/localize';
import LocalizedContext from '../../i18n/LocalizedContext';

import './FencerStats.css'

const FencerStats = props => {
    const stats = props.stats;
    const lang = useContext(LocalizedContext);

    const verbiage = localize(lang, 'finalResultComp');
    var progress = '';
    if( stats.progress === 0 ) {
        progress = <div className='FencingStats-line'>{verbiage}: <span className='FencingStats-zero'>{stats.progress}</span></div>;
    } else if ( stats.progress > 0 ) {
        progress = <div className='FencingStats-line'>{verbiage}: <span className='FencingStats-plus'>+{stats.progress}</span></div>;
    } else if ( stats.progress < 0 ) {
        progress = <div className='FencingStats-line'>{verbiage}: <span className='FencingStats-minus'>{stats.progress}</span></div>;
    }

    const touches = stats.touchesGiven - stats.touchesReceived;
    var touchesDesc = '';
    if( touches === 0 ) {
        touchesDesc = <span className='FencingStats-zero'>{touches}</span>;
    } else if( touches > 0 ) {
        touchesDesc = <span className='FencingStats-plus'>+{touches}</span>;
    } else if( touches < 0 ) {
        touchesDesc = <span className='FencingStats-minus'>{touches}</span>;
    }

    return (
        <div>
            <div className='FencingStats-line'>
                {localize(lang, 'finalResult')}: <span className='FencingStats-zero'>{stats.place}</span>
            </div>
            {progress}
            <div className='FencingStats-line'>
                {localize(lang, 'touches')}: <span className='FencingStats-plus'>{stats.touchesGiven}</span> {localize(lang, 'given')},
                &nbsp;&nbsp;
                <span className='FencingStats-minus'>{stats.touchesReceived}</span> {localize(lang, 'received')}
                &nbsp;&nbsp;
                ({localize(lang, 'difference')}: {touchesDesc})
            </div>
            <div className='FencingStats-line'>
                {localize(lang, 'bouts')}: <span className='FencingStats-plus'>{stats.wins}</span> {localize(lang, 'winsSmall')},
                &nbsp;&nbsp;
                <span className='FencingStats-minus'>{stats.defeats}</span> {localize(lang, 'defeatsSmall')}
            </div>
        </div>
    );
}

export default FencerStats;
