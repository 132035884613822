import React from 'react';

import './PointChart.css';

function PointChart({ data, metaData, width, height, xAxis, suffix }) {
  // Calculate the maximum value in the data array
  const xLow = Math.min( ...(data.map( xAxis )))
  const xHigh = Math.max( ...(data.map( xAxis )))
  const yLow = Math.min( ...(data.map( o => o.poolResult.tdMtr )))
  const yHigh = Math.max( ...(data.map( o => o.poolResult.tdMtr )))

  // Calculate the scale factor for the heights
  const scaleFactorX = width / ((xHigh - xLow) || 1);
  const scaleFactorY = height / ((yHigh - yLow) || 1);

  const xOffset = 60;
  const xOffsetAfter = 120;
  const yOffsetTop = 20;
  const yOffsetBottom = 40;
  const textOffset = 15;

  const xValueRaw = point => xOffset + (point - xLow) * scaleFactorX;
  const xValue = point => xValueRaw(xAxis(point));

  const yValueRaw = point => yOffsetTop + height - (point - yLow) * scaleFactorY;
  const yValue = point => yValueRaw(point.poolResult.tdMtr);

  // Generate the bars using the data array
  const points = data.map( (point, index) => (
    <circle
      className='PointChart-point'
      key={index}
      cx={xValue(point)}
      cy={yValue(point)}
      r='10'
    />
  ));

  const linear =
    <line
      className='PointChart-analysis'
      x1={xValueRaw(metaData.x1)}
      y1={yValueRaw(metaData.y1)}
      x2={xValueRaw(metaData.x2)}
      y2={yValueRaw(metaData.y2)}
    />;

  const description = data.map( (point, index) => (
    <text
      className='PointChart-text'
      key={index}
      x={xValue(point) + textOffset}
      y={yValue(point)}>
      {point.poolResult.indexV}V/{point.poolResult.indexT}
    </text>
  ));

  const CLOSE_AND_PRUNE = 10;
  const pruneXAxis = (source, target) => {
    if( source.length === 0 ) {
      return target
    } else {
      const e = source.shift();
      const ex = xAxis(e);

      if( ex < 40 ) {
        target.push( e );
      } else {
        const isClose = target.filter( o => Math.abs( xAxis(o) - ex ) < CLOSE_AND_PRUNE ).length;
        if( ! isClose ) target.push( e );
      }

      return pruneXAxis( source, target );
    }
  };

  const xAxisDescription = makeXAxis => makeXAxis.map( (point, index) => (
    <text
      className='PointChart-text'
      key={index}
      x={xValue(point) - 12}
      y={yOffsetTop + height + yOffsetBottom - 4}>
      {xAxis(point)}{suffix}
    </text>
  ));

  const yAxisDescription = makeYAxis => makeYAxis.map( (point, index) => (
    <text
      className='PointChart-text'
      key={index}
      x={14}
      y={yValue(point) + 5}>
      {point.poolResult.tdMtr}
    </text>
  ));

  // Calculate the total width of the chart
  const chartWidth = width + xOffset + xOffsetAfter;
  const chartHeight = height + yOffsetTop + yOffsetBottom;

  return (
    <svg width={chartWidth} height={chartHeight}>
      <rect
       className='PointChart-background'
       width={chartWidth}
       height={chartHeight}
       rx='5' />
      {linear}
      {points}
      {description}
      {xAxisDescription(pruneXAxis([...data], []))}
      {yAxisDescription(data)}

      <line className='PointChart'
        x1={xOffset - textOffset}
        x2={xOffset - textOffset}
        y1={yOffsetTop}
        y2={yOffsetTop + height + 2*textOffset } />
      <line className='PointChart'
        x1={xOffset - 2*textOffset}
        x2={xOffset + width + textOffset}
        y1={yOffsetTop + height + textOffset}
        y2={yOffsetTop + height + textOffset} />
    </svg>
  );
}

export default PointChart;

