import React from 'react';
import './NoAdmin.css';

const NoAdmin = () => {
    return (
        <div className='NoAdmin'>
            <br/>
            <b>Oooops! You are not an admin!</b>
            <br/>
            <br/>
            I appreciate your interest in helping me with the administrative tasks,
            but don't get too nosy either.
        </div>
    );
}

export default NoAdmin;