const urlParameters = () => {
    const urlParams = window.location.search.slice(1);
    
    // we'll store the parameters here
    const obj = {};
    
    // if query string exists
    if (urlParams) {
    
        // stuff after # is not part of query string, so get rid of it
        const queryString = urlParams.split('#')[0];
    
        // split our query string into its component parts
        const arr = queryString.split('&');
    
        arr.forEach( element => {
            // separate the keys and the values
            const a = element.split('=');
        
            // set parameter name and value (use 'true' if empty)
            const paramName = a[0];
            const paramValue = typeof (a[1]) === 'undefined' ? true : a[1];
            obj[paramName] = paramValue;
        });
    };
    
    return obj;
}

export default urlParameters;