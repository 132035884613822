import React, { useState } from 'react';

import Ok from './Ok';
import './Text.css';

const Text = props => {
    const header = props.header;
    const defaultValue = props.defaultValue;
    const submitHandler = props.submitHandler;

    const [inputValue, setInputValue] = useState(defaultValue);

    const clickHandler = () => {
        submitHandler(inputValue);
    }

    const changeInputHandler = event => {
        const data = event.target.value;
        setInputValue(data);
    };

    return (
        <div className='Text'>
            {header}:
            <input className='Text' type='text' onChange={changeInputHandler} value={inputValue} />
            { defaultValue === inputValue ? '' : <Ok clickHandler={clickHandler} /> }
        </div>
    );
}

export default Text;