import React, { useContext } from 'react';
import localize from '../i18n/localize';
import LocalizedContext from '../i18n/LocalizedContext';

import './ContactUs.css';

const Connecting = props => {

    const hide = props.hide;
    const lang = useContext(LocalizedContext);

    return (
        <div className='ContactUs' onClick={hide}>
            <div className='ContactUs-space'/>
            <div className='ContactUs-selector'>
                <div className='ContactUs-head'>
                    {localize(lang, 'contactUs')}
                </div>
                <div className='ContactUs-content'>
                    {localize(lang, 'contactDetail')}
                </div>
                <div className='ContactUs-content'>
                    {localize(lang, 'email')}: <a href='mailto:fencing@reactive-commerce.com'>fencing@reactive-commerce.com</a>
                </div>
            </div>
        </div>
    );
}

export default Connecting;