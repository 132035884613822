import React, { useContext } from 'react';

import localize from '../../i18n/localize';
import { tournamentName, eventName, eventDate, eventKey } from '../../utils/tournamentName';

import LocalizedContext from '../../i18n/LocalizedContext';

import './OneSerie.css';
import '../../essentials/EssentialButton.css';

const OneSerie = props => {
    const events = props.events;
    const setEventHandler = props.setEventHandler;
    const highlightEvent = props.highlightEvent;
    const selectedSerie = props.selectedSerie;
    const setSeriesSummaryHandler = props.setSeriesSummaryHandler;
    const lang = useContext(LocalizedContext);

    const eventList =
        <ul className='OneSerie'>
            { events.map( e => { 
                    const key = eventKey(e);
                    const firstName = highlightEvent ? eventName(e) : tournamentName(e);
                    const secondName = highlightEvent ? tournamentName(e) : eventName(e);
                
                    // Those coming through series are always approved. See repo impl.
                    return ( e.approved ?
                        <li key={key} className='OneSerie' onClick={ () => setEventHandler(e) }>
                            {eventDate(e)}&nbsp;<span className='OneSerie'>{firstName}</span>&nbsp;&nbsp;{secondName}
                        </li> :
                        <li key={key} className='OneSerie' onClick={ () => setEventHandler(e) }>
                            {eventDate(e)}&nbsp;{firstName}&nbsp;&nbsp;{secondName}
                        </li> );
                }
            ) }
        </ul>;

    const seriesHighlights = (selectedSerie && selectedSerie.competition === 'individual'  ) ?
        <div className='OneSerie-header'>
            {localize(lang, 'summarizedStatistics')}
            <button className='EssentialButton' onClick={setSeriesSummaryHandler}>
                {localize(lang, 'access')} {localize(lang, selectedSerie.organization)} {localize(lang, selectedSerie.gender)} {localize(lang, selectedSerie.weapon)} {localize(lang, 'statistics')}
            </button>
        </div> : '';

    return (
        <div className='OneSerie'>
            <div className='OneSerie-header'>
                {localize(lang, 'eventStats')}
            </div>
            {eventList}
            {seriesHighlights}
        </div>
    );
}

export default OneSerie;