import React, { useState, useCallback, useEffect, useContext } from 'react';
import localize from '../../i18n/localize';
import LocalizedContext from '../../i18n/LocalizedContext';

import './FencerSelector.css'

const NUM_COLUMNS = 3;

const FencerSelector = props => {
    const [searchTerm, setSearchTerm] = useState('');

    const searchEngine = props.searchEngine;
    const fencerSelectedHandler = props.changeFencer;
    const cancelHandler = props.cancelHandler;
    const verbiage = props.verbiage || 'searchForFencer';
    const lang = useContext(LocalizedContext);

    const doNothing = event => { event.stopPropagation(); };

    const fencers = searchEngine.searchTerm(searchTerm);
    const numFencers = fencers.length;
    const numRows = numFencers % NUM_COLUMNS === 0 ? numFencers / NUM_COLUMNS : numFencers / NUM_COLUMNS + 1;

    const changeSearchTermHandler = event => {
        const data = event.target.value;
        setSearchTerm(data);
    };

    const escFunction = useCallback((event) => {
        if (event.key === "Escape") {
            cancelHandler();
        }
    }, [cancelHandler]);
    
    useEffect(() => {
        document.addEventListener("keydown", escFunction, false);

        return () => {
            document.removeEventListener("keydown", escFunction, false);
        };
    }, [escFunction]);
    


    const makeEmptyCell = index => {
        return ( <td key={index}></td> );
    }
    
    const makeCell = (index, fencer, clickHandler) => {
        return ( <td key={index} className='FencerSelector-td' onClick={ () => clickHandler(fencer) } >
                    <span className='FencerSelector-text'>{fencer}</span>
                </td> );
    }
    
    const makeRow = (index, cells) => {
        return ( <tr key={index}>{cells}</tr> );
    }
    
    const makeTable = (rows, cancelHandler) => {
        return ( <div className='FencerSelector' onClick={cancelHandler}>
                    <div className='FencerSelector-selector'>
                        <div className='FencerSelector-search' onClick={doNothing}>
                            <div className='FencerSelector-header'>{localize(lang, verbiage)}:</div>
                            <input autoFocus className='FencerSelector-input' type='text' onChange={changeSearchTermHandler} value={searchTerm} />
                        </div>
                        <table className='FencerSelector-table'>
                            <tbody>
                                {rows}
                            </tbody>
                        </table>
                    </div>
                </div>
                );
    }

    let i = 0;

    let rows = [];
    for( let r = 0; r < numRows; r++ ) {
        const cells = [];
        for( let c = 0; c < NUM_COLUMNS; c++ ) {
            const cell = i >= numFencers ? makeEmptyCell(i) : makeCell(i, fencers[i], fencerSelectedHandler);
            cells.push(cell);
            i++;
        }
        rows.push(makeRow(i, cells));
    }

    return makeTable(rows, cancelHandler);
}

export default FencerSelector;