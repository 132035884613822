const localize = (lang, id, subs) => {
    if( id in lang ) {
        let wip = lang[id];
        if(subs) {
            const params = new Map(Object.entries(subs));
            for(const [key, value] of params) {
                if(wip.includes(key)) {
                    wip = wip.replace(new RegExp(key, 'g'), value); 
                } else {
                    console.error(`Translation of ${id} failed - ${key} not replaced`)
                }
                return wip;
            }
        } else {
            return wip;
        }
    } else {
        const msg = `Unknown ID "${id}"`;
        console.error(msg)
        return msg;
    }
}

export default localize;
