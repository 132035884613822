import React from 'react';

import './SimpleBarChart.css';

function SimpleBarChart({ data, barWidth, barHeight, prefix }) {
  // Calculate the scale factor for the heights
  const scaleFactor = barHeight / Math.max(...data);
  const xOffset = 40;
  const xOffsetAfter = 30;
  const yOffsetTop = 20;
  const yOffsetBottom = 20;

  const xValue = x => x * barWidth + xOffset;
  const yValue = y => yOffsetTop + barHeight - y * scaleFactor;

  // Generate the bars using the data array
  const bars = data.map((height, index) => {
    return <rect
      className='SimpleBarChart'
      key={index}
      x={xValue(index)}
      y={yValue(height)}
      width={barWidth}
      height={height * scaleFactor}
      rx='2'
    />;
  });

  const leftDescription = () => {
    // Find the maximum value in the data array
    const maxValue = Math.max(...data);

    // Define the number of divisions on the left axis
    const divisions = 5;

    // Calculate the interval between each division
    const interval = Math.ceil(maxValue / divisions);

    // Generate values for the left axis
    const leftAxisValues = [];
    for (let i = 0; i <= divisions; i++) {
      leftAxisValues.push(i * interval);
    }

    return leftAxisValues;
  };

  const leftAxis = leftDescription().map( (leftValue, index) => (
    <text
      className='SimpleBarChart'
      key={index}
      x='5'
      y={yValue(leftValue)} >
        {leftValue}
    </text>
  ));

  const bottomAxis = data.map( (height, index) => {
    return (
      <text
        className='SimpleBarChart'
        key={index}
        x={xValue(index) + (barWidth / 2) - 14}
        y={yOffsetTop + barHeight + yOffsetBottom - 4} >
        {prefix}{index}
      </text>
    );
  });

  // Calculate the total width of the chart
  const chartWidth = data.length * barWidth + xOffset + xOffsetAfter;
  const chartHeight = barHeight + yOffsetTop + yOffsetBottom;

  return (
    <svg width={chartWidth} height={chartHeight}>
      <rect
       className='SimpleBarChart-background'
       width={chartWidth}
       height={chartHeight}
       rx='5' />
      {bars}
      {data.length > 0 ? leftAxis : ''}
      {bottomAxis}
    </svg>
  );
}

export default SimpleBarChart;
