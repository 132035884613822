import React from 'react';

import '../essentials/EssentialButton.css';
import NameMapped from './NameMapped';

const SIZE = 5;   // Length of shown mappings

const NameMapper = props => {
    const mapping = props.mapping;
    const alternative = props.alternative;
    const setAlternativeHandler = props.setAlternativeHandler;
    const fetchMappingHandler = props.fetchMappingHandler;

    const mappingToDisplay = mapping.nameMapping.slice(0, SIZE).map( (name, i) => <NameMapped key={name.nameFound + i} data={name} fetchMapping={fetchMappingHandler} alternative={alternative} /> );
    const optionsToDisplay = mapping.options.map( name => <li key={name}><button className='EssentialButton' onClick={ () => setAlternativeHandler(name) }>{name}</button></li>); 

    return (
        <div>
            <button className='EssentialButton' onClick={fetchMappingHandler}>Check Name Mapping</button>
            <table>
                <tbody>
                    {mappingToDisplay}
                </tbody>
            </table>
            Alternatives
            <ol>
                {optionsToDisplay}
            </ol>
        </div>
    );
}

export default NameMapper;