import React, { useState } from 'react';

import Ok from './Ok';
import './SerieMgmt.css';

const KEEP = 'keep';
const DROP = 'drop';
const CHANGE = 'change';

const CADET = 'cadet';
const JUNIOR = 'junior';
const SENIOR = 'senior';
const OVER40 = 'over40';
const OVER50 = 'over50';
const OVER60 = 'over60';
const OVER70 = 'over70';

const INDIVIDUAL = 'individual';
const TEAM = 'team';

const MEN = 'men';
const WOMEN = 'women';

const CZECH = 'czech';
const FIE = 'fie';
const VETERAN = 'veteran';

const EPEE = 'epee';
const FOIL = 'foil';
const SABRE = 'sabre';

const descriptions = {
    KEEP: '---',
    DROP: 'Drop',
    CHANGE: 'Update',

    CADET: 'Cadet',
    JUNIOR: 'Junior',
    SENIOR: 'Senior',
    OVER40: '40+',
    OVER50: '50+',
    OVER60: '60+',
    OVER70: '70+',

    INDIVIDUAL: 'Individual',
    TEAM: 'Team',

    MEN: 'Men',
    WOMEN: 'Women',

    CZECH: 'Czech',
    FIE: 'FIE',
    VETERAN: 'Veteran',

    EPEE: 'Epee',
    FOIL: 'Foil',
    SABRE: 'Sabre'
};

const SerieMgmt = props => {
    const header = props.header;
    const series = props.series;
    const submitHandler = props.submitHandler;
    const key = props.myKey;

    const [action, setAction] = useState(KEEP);

    const [age, setAge] = useState(series.age);
    const [competition, setCompetition] = useState(series.competition);
    const [gender, setGender] = useState(series.gender);
    const [organization, setOrganization] = useState(series.organization);
    const [weapon, setWeapon] = useState(series.weapon);
    const [seriesIsEmpty, setSeriesIsEmpty] = useState(series.isEmpty);

    const [keyValue, setKeyValue] = useState(key);

    if( key !== keyValue ) {
        setKeyValue(key);
        setAction(KEEP);
        setAge(series.age);
        setCompetition(series.competition);
        setGender(series.gender);
        setOrganization(series.organization);
        setWeapon(series.weapon);
    }

    const clickHandler = () => {
        if( action === DROP ) {
            submitHandler('dropEventSeries', {});
            setSeriesIsEmpty(true);
        } else if( action === CHANGE ) {
            submitHandler('setEventSeries', {age, competition, gender, organization, weapon});
            setSeriesIsEmpty(false);
        }
        setAction(KEEP);
    }

    const changeActionHandler = event => {
        setAction(event.target.value);
        setAge(series.age);
        setCompetition(series.competition);
        setGender(series.gender);
        setOrganization(series.organization);
        setWeapon(series.weapon);
    };

    const changeAgeHandler = event => {
        setAge(event.target.value);
    };

    const changeCompetitionHandler = event => {
        setCompetition(event.target.value);
    };

    const changeGenderHandler = event => {
        setGender(event.target.value);
    };

    const changeOrganizationHandler = event => {
        setOrganization(event.target.value);
    };

    const changeWeaponHandler = event => {
        setWeapon(event.target.value);
    };

    const ageTag = 
        <select className='SerieMgmt' value={age} onChange={changeAgeHandler}>
            <option value={CADET}>{descriptions.CADET}</option>
            <option value={JUNIOR}>{descriptions.JUNIOR}</option>
            <option value={SENIOR}>{descriptions.SENIOR}</option>
            <option value={OVER40}>{descriptions.OVER40}</option>
            <option value={OVER50}>{descriptions.OVER50}</option>
            <option value={OVER60}>{descriptions.OVER60}</option>
            <option value={OVER70}>{descriptions.OVER70}</option>
        </select>;

    const competitionTag = 
        <select className='SerieMgmt' value={competition} onChange={changeCompetitionHandler}>
            <option value={INDIVIDUAL}>{descriptions.INDIVIDUAL}</option>
            <option value={TEAM}>{descriptions.TEAM}</option>
        </select>;

    const genderTag =
        <select className='SerieMgmt' value={gender} onChange={changeGenderHandler}>
            <option value={MEN}>{descriptions.MEN}</option>
            <option value={WOMEN}>{descriptions.WOMEN}</option>
        </select>;

    const organizationTag =
        <select className='SerieMgmt' value={organization} onChange={changeOrganizationHandler}>
            <option value={CZECH}>{descriptions.CZECH}</option>
            <option value={FIE}>{descriptions.FIE}</option>
            <option value={VETERAN}>{descriptions.VETERAN}</option>
        </select>;

    const weaponTag =
        <select className='SerieMgmt' value={weapon} onChange={changeWeaponHandler}>
            <option value={EPEE}>{descriptions.EPEE}</option>
            <option value={FOIL}>{descriptions.FOIL}</option>
            <option value={SABRE}>{descriptions.SABRE}</option>
        </select>;

    const showOk = (action === DROP) || (action === CHANGE);

    const showSeries = seriesIsEmpty ? 'empty' : age + '-' + competition + '-' + gender + '-' + organization + '-' + weapon;
    
    return (
        <div className='SerieMgmt'>
            {header}:
            ({ showSeries })
            <br/>
            <select className='SerieMgmt' value={action} onChange={changeActionHandler}>
                <option value={KEEP}>{descriptions.KEEP}</option>
                <option value={DROP}>{descriptions.DROP}</option>
                <option value={CHANGE}>{descriptions.CHANGE}</option>
            </select>
            { action === CHANGE ? <span>{ageTag} {competitionTag} {genderTag} {organizationTag} {weaponTag}</span> : '' }
            { showOk ? <Ok clickHandler={clickHandler} /> : '' }
        </div>
    );
}

export default SerieMgmt;