import React, { useContext } from 'react';
import localize from '../i18n/localize';
import LocalizedContext from '../i18n/LocalizedContext';

import './Cookies.css'

const Cookies = props => {
    const acceptCookieHandler = props.acceptCookieHandler;
    const lang = useContext(LocalizedContext);

    return (
        <div className='Cookies-canvas' onClick={ () => acceptCookieHandler() }>
            <div className='Cookies-content'>
                <p className='Cookies-paragraph'>
                    {localize(lang, 'cookieInfo')}
                    <span className='Cookies-understand' >&nbsp;{localize(lang, 'accept')}&nbsp;</span>
                </p>
            </div>
        </div>
    );
}

export default Cookies;