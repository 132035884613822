import React from 'react';
import './SelectedSerie.css';

import OneSerie from '../tournament/series/OneSerie';
import Text from './input/Text';

const SelectedSerie = props => {
    const selected = props.selected;
    const seriesNameChangeHandler = props.seriesNameChangeHandler;
    const setEventHandler = props.setEventHandler;

    const nameMaker = () => {
        if( selected.name ) return selected.name;
    
        const organization = selected.organization;
        const organizationUC = organization[0].toUpperCase() + organization.substring(1);
        const competition = selected.competition === 'team' ? 'team ' : ''; 

        return organizationUC + ' ' + competition + selected.weapon + ' ' + selected.gender + ' (' + selected.age + ')';
    }
    
    return (
        <div>
            <div className='SelectedSerie-header'>Series Management</div>
            <Text header='Series Name'
                defaultValue={nameMaker()}
                submitHandler={seriesNameChangeHandler} />
            <OneSerie events={selected.events} setEventHandler={setEventHandler}/>
        </div>
    );
}

export default SelectedSerie;