import React, { useContext } from 'react';

import localize from '../../i18n/localize';
import { tournamentName, eventName } from '../../utils/tournamentName';

import LocalizedContext from '../../i18n/LocalizedContext';

import './FinalResults.css';

const OFFSET = 20;
const BOX_WIDTH = 300;
const BOX_HEIGHT = 150;
const MAX_VALUES = 5;       // as in the BE

const makePlacementGraph = (values, maxValue, isInverse, unit) => {

    // step in [0 .. MAX_VALUES - 1]
    const getX = step => {
        const STEP_X = (BOX_WIDTH - 2 * OFFSET) / (MAX_VALUES + 2);    // #values + edges
        return Math.round(STEP_X * (1 + step) + OFFSET);
    };

    const getY = value => {
        const v = (value * (BOX_HEIGHT - 2 * OFFSET) / maxValue) + OFFSET;
        if( isInverse ) return BOX_HEIGHT - v;
        else return v;
    };

    const linesY = [
        0.2 * maxValue,
        0.4 * maxValue,
        0.6 * maxValue,
        0.8 * maxValue,
      ];

    const lines = linesY.map( (y, index) => {
        return (
            <line
                key={ index }
                x1={ getX( -1 ) }
                y1={ getY( y ) }
                x2={ getX( MAX_VALUES ) }
                y2={ getY( y ) }
                stroke='#BBB'
                strokeWidth='1'
                strokeDasharray='2 4'
            />);
    });

    const leftLegend = linesY.map( (y, index) => {
       return (
            <text
                key={ index }
                x='0'
                y={ getY( y ) }
                className='FinalResults-text'
            >{ y }</text>); 
    });

    const bottomLegend = values.map( (p, index) => {
        return (
            <text
                key={ index }
                x={ getX( index ) - 5 }
                y={ BOX_HEIGHT - 2 }
                className='FinalResults-text'
            >{ '(' + (index+1) + ')' }</text>
        );
    });

    const points = values.map( (p, index) => {
        return (
            <circle
                key={ index }
                cx={ getX( index ) }
                cy={ getY( p ) }
                r='4'
                fill='#D75B66'
            />);
    });


    const pointValues = values.map( (p, index) => {
        return (
            <text
                key={ index }
                x={ getX( index ) - 5 }
                y={ getY( p ) + 10 }
                className='FinalResults-values'
            >{ p + unit }</text>
        );
    });

    const lineMaker = values => {
        if( values.length < 2 ) return '';

        var currentHorizontal = getX( 0 );
        var currentVertical = getY( values[0] );
        const bezier = 10;
    
        var collector = 'M ' + currentHorizontal + ' ' + currentVertical;
    
        for(var index = 1; index < values.length; index++) {
            collector += ' C ' + (currentHorizontal + bezier) + ' ' + currentVertical;
    
            currentVertical = getY( values[index] );
            currentHorizontal = getX( index );
    
            collector += ', ' + (currentHorizontal - bezier) + ' ' + currentVertical;
            collector += ', ' + currentHorizontal + ' ' + currentVertical;
        }
    
        return <path stroke='#D75B66' fill='transparent' strokeWidth='2' d={collector}/>
    }

    const svgDimensions = '0 0 ' + BOX_WIDTH + ' ' + BOX_HEIGHT;

    return (
        <svg viewBox={svgDimensions}>
            {lines}
            {leftLegend}
            {bottomLegend}
            {points}
            {lineMaker(values)}
            {pointValues}
        </svg>
    );
}

// because we have 4 lines in the middle
const computeMax = places => {
    const max = Math.max( ...places );
    const newMax = (Math.floor(max / 5) + 1) * 5;
    return newMax;
}

const FinalResults = props => {
    const fencer = props.fencer;
    const finalResults = props.finalResults;
    const lang = useContext(LocalizedContext);

    if( finalResults.length > 0 ) {
        const finalResultsIndex = finalResults.map( (a, i) => <li key={i}>{tournamentName(a)} {eventName(a)}</li> );

        const places = finalResults.map( f => { return f.finalPlace; } );
        const placementGraph = makePlacementGraph(
            places,
            computeMax( places ),
            false,
            '.');

        const pctage = finalResults.map( f => { return f.topPercentage; } );
        const pctageGraph = makePlacementGraph(
            pctage,
            100,
            false,
            '%');

        const deWins = finalResults.map( f => { return f.deWins; } );
        const deWinsGraph = makePlacementGraph(
            deWins,
            computeMax( deWins ),
            true,
            'V');

        return (
            <div>
                <div className='FinalResults-label'>{localize(lang, 'finalResults')}</div>
                <div className='FinalResults-description'>{localize(lang, 'resultsAfterEvent')}:</div>
                {placementGraph}
                <ol className='FinalResults-events'>{finalResultsIndex}</ol>
                <div className='FinalResults-label'>{localize(lang, 'topFinisher')}</div>
                <div className='FinalResults-description'>{localize(lang, 'finisherDisplayed')}:</div>
                {pctageGraph}
                <ol className='FinalResults-events'>{finalResultsIndex}</ol>
                <div className='FinalResults-label'>{localize(lang, 'eliminationsWon')}</div>
                <div className='FinalResults-description'>{localize(lang, 'numberOfWonDE')}:</div>
                {deWinsGraph}
                <ol className='FinalResults-events'>{finalResultsIndex}</ol>
            </div> );
    } else {
        return (
            <div>
                <div className='FinalResults-label'>{localize(lang, 'finalResults')}</div>
                <div className='FinalResults-description'>{localize(lang, 'noDataHas', {'@userid@': fencer})}</div>
            </div>
        );
    }
}

export default FinalResults;