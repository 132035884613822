import React, { useState } from 'react';

import './TournamentNameMgmt.css';
import callBackend from '../../utils/callBackend';

const TournamentNameMgmt = props => {
    const tournament = props.tournament;

    const [manCity, setManCity] = useState(tournament.manCity || '');
    const [manLevel, setManLevel] = useState(tournament.manLevel || '');
    const [manName, setManName] = useState(tournament.manName || '');

    const changeManCityValueHandler = event => {
        setManCity(event.target.value);
    };

    const changeManLevelValueHandler = event => {
        setManLevel(event.target.value);
    };

    const changeManNameValueHandler = event => {
        setManName(event.target.value);
    };

    const clickUpdateHandler = () => {
        const payload = { tournamentPk: tournament.pk };
        if( manCity ) payload.manCity = manCity;
        if( manLevel ) payload.manLevel = manLevel;
        if( manName ) payload.manName = manName;

        callBackend('updateTournament', payload);
    };

    return (
        <div className='TournamentNameMgmt'>
            Level: <input className='TournamentNameMgmt TournamentNameMgmt-level'
                type='text'
                onChange={changeManLevelValueHandler}
                value={manLevel} />

            City: <input className='TournamentNameMgmt TournamentNameMgmt-city'
                type='text'
                onChange={changeManCityValueHandler}
                value={manCity} />

            Name: <input className='TournamentNameMgmt TournamentNameMgmt-name'
                type='text'
                onChange={changeManNameValueHandler}
                value={manName} />

            <button className='TournamentNameMgmt' onClick={clickUpdateHandler}>Confirm</button>
        </div>
    );
}

export default TournamentNameMgmt;