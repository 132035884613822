import React from 'react';

import './Ok.css';
import './Date.css';

const EventOperations = props => {
    const header = props.header;
    const submitHandler = props.submitHandler;

    const clickDropSeriesHandler = () => {
        submitHandler('dropEventSeries', {});
    }

    const clickUpdateEloHandler = () => {
        submitHandler('eventAction', {eventAction: 'updateElo', isTeam: false}); // isTeam not used on BE
    }

    return (
        <div className='Date'>
            {header}:
            <button className='Ok' onClick={clickDropSeriesHandler}>Drop Series</button>
            <button className='Ok' onClick={clickUpdateEloHandler}>Update ELO</button>
        </div>
    );
}

export default EventOperations;