const textComparator = () => {

    const charToAscii = char => {
        switch(char) {
            case 'á':
            case 'à':
            case 'ä':
                return 'a';
            case 'é':
            case 'ě':
            case 'è':
                return 'e';
            case 'í':
                return 'i';
            case 'ó':
            case 'ö':
                return 'o';
            case 'ú':
            case 'ü':
            case 'ù':
            case 'ů':
                return 'u';
            case 'ý':
                return 'y';

            case 'ž':
                return 'z';
            case 'š':
                return 's';
            case 'č':
            case 'ç':
                return 'c';
            case 'ř':
                return 'r';
            case 'ď':
                return 'd';
            case 'ť':
                return 't';
            case 'ň':
                return 'n';

            default:
                return char;
        }
    }

    const textToAscii = text => {
        return text.toLowerCase().split('').map( c => charToAscii(c) ).join('');
    }

    const equalTextInAscii = (a, b) => {
        if( a && b ) {
            if( a.length !== b.length ) {
                return false;
            } else {
                return textToAscii(a) === textToAscii(b);
            }
        } else {
            return false;
        }
    }

    return { equalTextInAscii };
}

export default textComparator;