import React from 'react';

const EventContent = props => {
    const data = props.data;

    const comparator = (a, b) => {
        if( a.result < b.result ) return -1;
        if( a.result > b.result ) return 1;
        return 0;
    }

    const extracted = data
        .map( f => { return { result: f.results.at(-1), name: f.name, finalResult: f.finalResult } } )
        .sort( comparator )
        .map( (f, inx) => <li key={inx}>{f.result}: {f.name} {f.finalResult ? (f.finalResult - f.result) : '--' }</li> );

    return ( <ul>{extracted}</ul> );
}

export default EventContent;