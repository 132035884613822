import React, { useState, useCallback, useEffect } from 'react';

import Connecting from '../dataStatus/Connecting';
import NoData from '../dataStatus/NoData';
import NoAdmin from './NoAdmin';
import callBackend from '../utils/callBackend';

import TabHeader from './tabs/TabHeader';
import TabItem from './tabs/TabItem';
import Translations from './Translations';
import SelectedSerie from './SelectedSerie';
import SelectedEvent from './SelectedEvent';
import SelectedTournament from './SelectedTournament';
import Series from '../tournament/series/Series';
import EventContent from './input/EventContent';
import NameMapper from './NameMapper';
import hashMaker from '../utils/hashMaker';

const emptySerie = {
    isEmpty: true,
    seriesPk: 0,
    name: '---',
    age: 'senior',
    competition: 'individual',
    gender: 'men',
    organization: 'czech',
    weapon: 'epee',
    events: []   
};

const TOURNAMENT = 'TOURNAMENT';
const SERIES = 'SERIES';
const TRANSLATION = 'TRANSLATION';

const Admin = () => {
    const [isAdmin, setIsAdmin] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    const [series, setSeries] = useState([]);
    const [tournament, setTournament] = useState({ events: [] });
    const [organization, setOrganization] = useState(localStorage.getItem( 'ORGANIZATION' ) || 'fie');

    const [selectedTab, setSelectedTab] = useState(TOURNAMENT);
    const [selectedSeriePk, setSelectedSeriePk] = useState(null);
    const [selectedEventPk, setSelectedEventPk] = useState(null);
    const [content, setContent] = useState([]);

    const [mapping, setMapping] = useState({nameMapping: [], options: []});
    const [alternative, setAlternative] = useState('');

    // Initial fetch
    const fetchAdminHandler = useCallback( () => {
        setIsLoading(true);
        setError(null);

        callBackend('isAdmin', null, result => {
            if( result.isOk ) {
                if( result.data.success ) {
                    setIsAdmin( true );
                    callBackend('tournamentAdmin', {returning: 'last'}, result => {
                        if( result.isOk ) {
                            setTournament(result.data);
                        }
                    });

                    callBackend('seriesAdmin', null, result => {
                        if( result.isOk ) {
                            setSeries(result.data.series);
                        }
                    });
                }
            } else {
                setError(result.error);
            }
            setIsLoading(false);
        });
    }, []);

    useEffect(() => {
        fetchAdminHandler();
    }, [fetchAdminHandler] );

    // Tabs & data
    const setTabHandler = t => {
        setSelectedTab(t);
        setSelectedSeriePk(null);
        setSelectedEventPk(null);
        setContent([]);
        setMapping({nameMapping: [], options: []});
        setAlternative('');
    }

    const setSeriesHandler = s => {
        if( s ) {
            setSelectedSeriePk(s.seriesPk);
        } else {
            setSelectedSeriePk(null);
        }
        setSelectedEventPk(null);
        setContent([]);
        setMapping({nameMapping: [], options: []});
        setAlternative('');
    }

    const setEventHandler = e => {
        setSelectedEventPk(e.eventPk);
        setMapping({nameMapping: [], options: []});
        setAlternative('');

        callBackend('results', { checksum: hashMaker(e.eventPlace.eventName), eventPk: e.eventPk, noCache: true }, result => {
            if( result.isOk ) {
                setContent(result.data.fencers);
            }
        });
    }

    const setEventAndSerieHandler = e => {
        setMapping({nameMapping: [], options: []});
        setAlternative('');
        if( e ) {
            const serie = series.filter( s => s.events.filter( localEv => localEv.eventPk === e.eventPk ).length > 0 );
            if( serie.length > 0 ) {
                setSelectedSeriePk(serie[0].seriesPk);
            } else {
                setSelectedSeriePk(null);
            }
            setEventHandler(e);
        } else {
            setSelectedSeriePk(null);
            setSelectedEventPk(null);
            setContent([]);
        }
    }

    // Call backend - SETTERs and GETTERs
    const changeTournamentHandler = req => {
        setSelectedEventPk(null);
        setContent([]);
        setMapping({nameMapping: [], options: []});
        setAlternative('');
        callBackend('tournamentAdmin', req, result => {
            if( result.isOk && result.data ) {
                setTournament(result.data);
            }
        });
    };

    const refresh = () => {
        setMapping({nameMapping: [], options: []});
        setAlternative('');
        callBackend('seriesAdmin', null, result => {
            if( result.isOk ) {
                const localSeries = result.data.series;
                setSeries(localSeries);

                const serie = localSeries.filter( s => s.events.filter( localEv => localEv.eventPk === selectedEventPk ).length > 0 );
                if( serie.length > 0 ) {
                    setSelectedSeriePk(serie[0].seriesPk);
                }
            }
        });
        callBackend('tournamentAdmin', {returning: 'this', tournamentPk: tournament.pk}, result => {
            if( result.isOk ) {
                setTournament(result.data);
            }
        });
    };

    const seriesNameChangeHandler = value => {
        callBackend('setSeriesName', { seriesPk: selectedSeriePk, name: value }, result => {
            if( result.isOk ) {
                refresh();
            }
        });
    };

    const updateRainbowHandler = req => {
        callBackend('setRainbow', req);
        setTournament( { ...tournament, rainbow: req.rainbow } );
    };

    const eventChangeHandler = (action, value) => {
        setMapping({nameMapping: [], options: []});
        setAlternative('');
        callBackend(action, { eventPk: selectedEventPk, ...value }, result => {
            if( result.isOk ) {
                refresh();
            }
        });
    };

    if (error) return <NoData/>;
    if (isLoading) return <Connecting/>;
    if (!isAdmin) return <NoAdmin/>;

    const selectedSerieArray = series.filter( s => s.seriesPk === selectedSeriePk )
    const hasSelectedSeries = selectedSerieArray.length > 0;
    const selectedSerie = hasSelectedSeries ? selectedSerieArray[0] : emptySerie;

    const findSelectedEvent = () => {
        if( selectedTab === SERIES )
            return hasSelectedSeries > 0 ? selectedSerie.events.filter( e => e.eventPk === selectedEventPk )[0] : null;
        if( selectedTab === TOURNAMENT ) {
            const eventArray = tournament.events.filter( e => e.eventPk === selectedEventPk )
            return eventArray.length > 0 ? eventArray[0] : null;
        }
        return null;
    }

    const selectedEvent = findSelectedEvent();

    const organizations = [...new Set(series.map( s => s.organization))];
    const seriesForOrg = series.filter( s => s.organization === organization);

    const fetchMapping = () => {
        if( selectedEvent ) {
            callBackend('listNameMapping', {eventPk: selectedEvent.eventPk, eventAction: 'unusedFencers', isTeam: selectedEvent.isTeam }, result => {
                if( result.isOk ) {
                    setMapping(result.data);
                }
            });
        }
    };

    return (
        <div>
            <TabHeader>
                <TabItem isSelected={selectedTab === TOURNAMENT} selectionHandler={ () => setTabHandler(TOURNAMENT) }>Tournament</TabItem>
                <TabItem isSelected={selectedTab === SERIES} selectionHandler={ () => setTabHandler(SERIES) }>Series</TabItem>
                <TabItem isSelected={selectedTab === TRANSLATION} selectionHandler={ () => setTabHandler(TRANSLATION) }>Content</TabItem>
            </TabHeader>

            {selectedTab === TOURNAMENT ?
                <div>
                    <SelectedTournament
                        selected={tournament}
                        changeTournamentHandler={changeTournamentHandler}
                        updateRainbowHandler={updateRainbowHandler}
                        setEventHandler={setEventAndSerieHandler}/>
                    <SelectedEvent
                        selected={selectedEvent}
                        series={selectedSerie}
                        eventChangeHandler={eventChangeHandler} />
                    <EventContent data={content} />
                    <NameMapper
                        mapping={mapping}
                        alternative={alternative}
                        setAlternativeHandler={setAlternative}
                        fetchMappingHandler={fetchMapping} />
                </div> : '' }

            {selectedTab === SERIES ?
                <Series series={seriesForOrg} setSeriesHandler={setSeriesHandler}
                    organization={organization} organizations={organizations} setOrganizationHandler={setOrganization} >
                    { hasSelectedSeries ?
                    <SelectedSerie
                        selected={selectedSerie}
                        seriesNameChangeHandler={seriesNameChangeHandler}
                        setEventHandler={setEventHandler} /> : '' }
                    <SelectedEvent
                        selected={selectedEvent}
                        series={selectedSerie}
                        eventChangeHandler={eventChangeHandler} />
                    <EventContent data={content} />
                    <NameMapper
                        mapping={mapping}
                        alternative={alternative}
                        setAlternativeHandler={setAlternative}
                        fetchMappingHandler={fetchMapping} />
                </Series> : '' }

            {selectedTab === TRANSLATION ?
                <Translations/> : '' }
        </div>
    );
}

export default Admin;