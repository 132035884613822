import React, { useState } from 'react';

import Ok from './Ok';
import './Quality.css';

const OK = 'ok';
const WARNING = 'warning';
const ERROR = 'error';

const descriptions = {
    OK: 'No Issue',
    WARNING: 'Check (show)',
    ERROR: 'Bad (hide)'
};

const Quality = props => {
    const header = props.header;
    const defaultValue = props.defaultValue || '';
    const quality = props.quality;
    const submitHandler = props.submitHandler;
    const key = props.myKey;

    const [inputValue, setInputValue] = useState(defaultValue);
    const [qualityValue, setQualityValue] = useState(quality);
    const [keyValue, setKeyValue] = useState(key);

    // after event change event
    if( key !== keyValue ) {
        setKeyValue(key);
        setInputValue(defaultValue);
        setQualityValue(quality);
    }

    const clickHandler = () => {
        submitHandler('setEventQuality', {qualityText: inputValue, quality: qualityValue});
    }

    const changeInputHandler = event => {
        setInputValue(event.target.value);
    };

    const changeSelectHandler = event => {
        setQualityValue(event.target.value);
    }

    const hideOk = qualityValue === OK ? qualityValue === quality : (defaultValue === inputValue && qualityValue === quality);

    return (
        <div className='Quality'>
            {header}:
            <select className='Quality' value={qualityValue} onChange={changeSelectHandler}>
                <option value={OK}>{descriptions.OK}</option>
                <option value={WARNING}>{descriptions.WARNING}</option>
                <option value={ERROR}>{descriptions.ERROR}</option>
            </select>
            { qualityValue !== OK ? <input className='Quality' type='text' onChange={changeInputHandler} value={inputValue} /> : '' }
            { hideOk ? '' : <Ok clickHandler={clickHandler} /> }
        </div>
    );
}

export default Quality;