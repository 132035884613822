import React, { useState } from 'react';

import Ok from './Ok';
import './Date.css';


const YEAR = 'y';
const MONTH = 'm';
const DAY = 'd';

const get = ( part, data ) => {
    if( data ) {
        const parts = data.split('-');
        if( parts.length === 3 ) {
            switch( part ) {
                case YEAR: return parts[0];
                case MONTH: return parts[1];
                case DAY: return parts[2];
                default: return '';
            }
        } else {
            return '';
        }
    } else {
        return '';
    }
};

const isoDate = (year, month, day) => {
    return year + '-' + month + '-' + day;
}

const Date = props => {
    const header = props.header;
    const defaultValue = props.defaultValue;
    const submitHandler = props.submitHandler;
    const key = props.myKey;

    const [year, setYear] = useState(get(YEAR, defaultValue));
    const [month, setMonth] = useState(get(MONTH, defaultValue));
    const [day, setDay] = useState(get(DAY, defaultValue));
    const [keyValue, setKeyValue] = useState(key);

    // after event change event
    if( key !== keyValue ) {
        setKeyValue(key);
        setYear(get(YEAR, defaultValue));
        setMonth(get(MONTH, defaultValue));
        setDay(get(DAY, defaultValue));
    }

    const clickHandler = () => {
        submitHandler('setEventDate', {eventIsoDate: isoDate(year, month, day) });
    }

    const changeYearHandler = event => {
        setYear(event.target.value);
    };

    const changeMonthHandler = event => {
        setMonth(event.target.value);
    };

    const changeDayHandler = event => {
        setDay(event.target.value);
    };

    return (
        <div className='Date'>
            {header}:
            Year: <input className='Date' type='text' onChange={changeYearHandler} value={year} />
            Month: <input className='Date' type='text' onChange={changeMonthHandler} value={month} />
            Day: <input className='Date' type='text' onChange={changeDayHandler} value={day} />
            ( { isoDate(year, month, day) } )
            { defaultValue === isoDate(year, month, day) ? '' : <Ok clickHandler={clickHandler} /> }
        </div>
    );
}

export default Date;