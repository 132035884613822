import React from 'react';
import './Gartner.css';

import constants from './constants';
import textComparator from '../../utils/textComparator';

const normalize = (data, max, margin) => {
    const minimum = Math.min(...data);
    const zeroBasedData = data.map( e => e - minimum );

    const maximum = Math.max(...zeroBasedData);
    const q = maximum / (max - 2 * margin);
    const normalizedData = zeroBasedData.map(  e => Math.round(e / q) + margin );

    return normalizedData;
}

const Gartner = props => {
    const fencerName = props.fencer;
    const data = props.data;
    const labels = Object.fromEntries(Object.entries(props.labels).map(([key, value]) => [key, value.toUpperCase()]));
    const leftFencer = props.leftFencer;
    const rightFencer = props.rightFencer;

    if( data.length === 0 ) return <div>no data</div>;

    const svgDimensions = '0 0 ' + constants.WIDTH + ' ' + constants.WIDTH;

    const xVals = normalize( data.map( e => e.xValue ), constants.WIDTH, 3* constants.GARTNER_HOT_POINT );
    const yVals = normalize( data.map( e => e.yValue ), constants.WIDTH, 3* constants.GARTNER_HOT_POINT );
    const normalized = data.map( (e, i) => { return {
        x: xVals[i],
        y: (constants.WIDTH - yVals[i]),     // inverted axis y
        fencerName: e.fencerName
    }} );

    const points = data.map( (e, i) => <circle className='point' key={i} cx={normalized[i].x} cy={normalized[i].y} r={constants.GARTNER_POINT} /> )

    const hot = normalized.find( e => textComparator().equalTextInAscii(e.fencerName, fencerName) );
    const hotLeft = normalized.find( e => textComparator().equalTextInAscii(e.fencerName, leftFencer) );
    const hotRight = normalized.find( e => textComparator().equalTextInAscii(e.fencerName, rightFencer) );

    const hotPoint = hot ? <circle className='hotPoint' cx={hot.x} cy={hot.y} r={constants.GARTNER_HOT_POINT} /> : '';
    const hotPointLeft = hotLeft ? <circle className='leftPoint' cx={hotLeft.x} cy={hotLeft.y} r={constants.GARTNER_HOT_POINT+6} /> : '';
    const hotPointRight = hotRight ? <circle className='rightPoint' cx={hotRight.x} cy={hotRight.y} r={constants.GARTNER_HOT_POINT} /> : '';

    const leftBottom = <text x={constants.GARTNER_TEXT_OFFSET} y={constants.WIDTH - constants.GARTNER_TEXT_OFFSET} className='textLeft'>{labels['leftBottom']}</text>
    const rightBottom = <text x={constants.WIDTH - constants.GARTNER_TEXT_OFFSET} y={constants.WIDTH - constants.GARTNER_TEXT_OFFSET} className='textRight'>{labels['rightBottom']}</text>
    const leftTop = <text x={constants.GARTNER_TEXT_OFFSET} y={constants.GARTNER_TEXT_OFFSET} className='textLeft'>{labels['leftBottom']}</text>
    const rightTop = <text x={constants.WIDTH - constants.GARTNER_TEXT_OFFSET} y={constants.GARTNER_TEXT_OFFSET} className='textRight'>{labels['rightBottom']}</text>

    const transformTopLeft = 'translate(' + constants.GARTNER_TEXT_OFFSET + ',' + constants.GARTNER_TEXT_OFFSET + ') rotate(-90)';
    const topLeft = <text x='0' y='0' transform={transformTopLeft} className='textRight'>{labels['topLeft']}</text>
    const transformBottomLeft = 'translate(' + constants.GARTNER_TEXT_OFFSET + ',' + (constants.WIDTH - constants.GARTNER_TEXT_OFFSET) + ') rotate(-90)';
    const bottomLeft = <text x='0' y='0' transform={transformBottomLeft} className='textLeft'>{labels['bottomLeft']}</text>

    const transformTopRight = 'translate(' + (constants.WIDTH - constants.GARTNER_TEXT_OFFSET) + ',' + constants.GARTNER_TEXT_OFFSET + ') rotate(-90)';
    const topRight = <text x='0' y='0' transform={transformTopRight} className='textRight'>{labels['topLeft']}</text>
    const transformBottomRight = 'translate(' + (constants.WIDTH - constants.GARTNER_TEXT_OFFSET) + ',' + (constants.WIDTH - 2*constants.GARTNER_TEXT_OFFSET) + ') rotate(-90)';
    const bottomRight = <text x='0' y='0' transform={transformBottomRight} className='textLeft'>{labels['bottomLeft']}</text>

    const q1 = <text x={constants.WIDTH / 4} y={constants.WIDTH / 4} className='textMiddle'>{labels['q1']}</text>
    const q2 = <text x={( 3*constants.WIDTH ) / 4} y={constants.WIDTH / 4} className='textMiddle'>{labels['q2']}</text>
    const q3 = <text x={constants.WIDTH / 4} y={( 3*constants.WIDTH ) / 4} className='textMiddle'>{labels['q3']}</text>
    const q4 = <text x={( 3*constants.WIDTH ) / 4} y={( 3*constants.WIDTH ) / 4} className='textMiddle'>{labels['q4']}</text>

    return (
        <svg viewBox={svgDimensions} width="350px" height="350px">
            {points}

            {leftBottom}
            {rightBottom}
            {leftTop}
            {rightTop}
            {topLeft}
            {bottomLeft}
            {topRight}
            {bottomRight}

            {q1}
            {q2}
            {q3}
            {q4}
            
            {hotPoint}
            {hotPointLeft}
            {hotPointRight}
        </svg>
    );
}

export default Gartner