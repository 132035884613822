import React, { useState, useCallback, useEffect, useContext } from 'react';
import localize from '../../i18n/localize';
import LocalizedContext from '../../i18n/LocalizedContext';

import './Opponent.css';

import OpponentBout from './OpponentBout';
import callBackend from '../../utils/callBackend';

const Opponent = props => {
    const fencer = props.fencer;
    const opponent = props.opponent;
    const event = props.event;
    const lang = useContext(LocalizedContext);

    const [bouts, setBouts] = useState({});
    const [ctw, setCtw] = useState(null);
    const [noResults, setNoResults] = useState(false);

    const eventPk = event.eventPk;
    const chooseOpponentHandler =  useCallback( () => {
        callBackend('opponent', { fencer, opponent, eventPk }, result => {
            if( result.isOk ) {
                const data = result.data;
                const boutResult = data.results;
                if( Object.keys( boutResult ).length ) {
                    setBouts(boutResult);
                } else {
                    setNoResults(true);
                }
                setCtw(data.ctw);
            }
        });
    }, [fencer, opponent, eventPk]);

    useEffect(() => {
        chooseOpponentHandler();
    }, [chooseOpponentHandler, fencer, opponent] );

    const ctwDisplay = ctw ?
        <div>
            <div className='Opponent-message'>{localize(lang, 'ctwExplanation')}</div>
            <div className='Opponent-ctw'>CTW: {ctw}%</div>
        </div> : '';

    if( noResults ) return (
        <div className='Opponent-message'>
            { ctwDisplay }
            <div className='Opponent-message'>{localize(lang, 'noRecords')}</div>
        </div>
    );

    const makeBoutList = (boutList, description) => {
        if( !boutList ) return '';

        return (
            <div>
                <div className='Opponent-header'>{description}</div>
                <ul className='Opponent'>
                    { boutList.map( (b, index) => <li className='Opponent' key={index}><OpponentBout bout={b}/></li>) }
                </ul>
            </div>
        );
    };

    const individual = makeBoutList(bouts.individual, 'Individual results');
    const team = makeBoutList(bouts.team, 'Team results');
    const teamMember = makeBoutList(bouts.teamMember, 'Team member results');

    return (
        <div>
            <div className='Opponent-match'>{fencer} {localize(lang, 'vs')} {opponent}</div>
            { ctwDisplay }
            { individual }
            { team }
            { teamMember }
        </div>
    );
}

export default Opponent;
