import React, { useContext } from "react";
import localize from '../i18n/localize';
import LocalizedContext from '../i18n/LocalizedContext';

import './CheckoutStep.css';
import BottomBar from './BottomBar';

const CheckoutThankYou2 = props => {
    const cancelCheckout = props.cancelCheckout;
    const lang = useContext(LocalizedContext);

    return (
        <div className='CheckoutStep-content'>
            <div className='CheckoutStep'>
                <div className='CheckoutStep-header'>
                    {localize(lang, 'nextSteps')}:
                </div>
                <ol>
                    <li className='CheckoutStep'>{localize(lang, 'weContact')}</li>
                    <li className='CheckoutStep'>{localize(lang, 'youPayHonor')}</li>
                </ol>
            </div>
            <BottomBar cancelCheckout={cancelCheckout} dismiss={true} />
        </div>);
}

export default CheckoutThankYou2